import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { base_url } from '../Mode';
import CallIcon from '@mui/icons-material/Call';
import {
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  getFormattedTimestamp,
  CopyIcon,
  splitByUnderscoresAndCapitalize,
  currentAdminIsAdmin,
  currentAdminIsManager,
  getTimeZoneFormattedTimestamp,
  currentAdminIsIC,
} from '../../utils/common';
import { CurrentHotLeadWarningBox } from '../../utils/CurrentHotLeadWarningBox';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addDays, getTimePeriod, subtractDays } from '../Sidebar';
import LeadEditForm from './LeadEditForm';
import EditIcon from '@mui/icons-material/Edit';
import { useAllAdmins } from '../AdminsContext';
import { useCurrentLead } from '../CurrentLeadContext';
import PartialViewLeadEditForm from './PartialViewLeadEditForm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LeadCommunications from './LeadCommunications';
import ConfettiEffect from './ConfettingEffect';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { InteractionsList } from './InteractionsList';
import InteractionFileUploads from './InteractionFileUploads/InteractionFileUploads';
import EligibilityFormDialog from './EligibilityFormDialog';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ExternalTabComponents from './ExternalTabComponents';
import CallButton from '../callAnimation/CallButton';
import { useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeadDropRequestDetails from './LeadDropRequestDetails';
import io from 'socket.io-client';
import { useSocket } from '../../socket/socketContext';
import { fetchCallLogs, getCPEDetails } from '../SpeechRecognition/utils';
import ForumIcon from '@mui/icons-material/Forum';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { FollowUpTimeConfirmationModal } from './FollowUpTimeConformationModal';
import SendEligibilityFormBanner from '../SendEligibilityFormBanner';
import { useCurrentPostSalesLead } from '../CurrentPostSalesLeadContext';
import PostSalesPaymentTab from '../Conversions/PostSalesDashboard/PostSalesLeadDetailsSection/PostSalesPaymentTab';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function LeadDetailsModal(props) {
  const {
    currentLead,
    setCurrentLead,
    getCurrentLeadInteractionsAndUpdateContext,
    getCurrentLeadAndUpdateContext,
    leadFocusMode,
    currentLeadActivities,
    currentLeadInteractions,
    currentLeadStatusActivities,
    currentLeadSignups,
    getCurrentLeadActivitiesAndUpdateContext,
    getCurrentLeadStatusActivitiesAndUpdateContext,
  } = useCurrentLead();
  // const { fetchPostSalesLeadData, leadData } = useCurrentPostSalesLead();
  const [newInteractionNote, setNewInteractionNote] = React.useState('');
  const [interactionInProgress, setInteractionInProgress] =
    React.useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successText, setSuccessText] = React.useState('');
  const [openLeadDetailsDialog, setLeadDetailsDialog] = React.useState(true);
  const [openEditLeadDetailsDialog, setOpenEditLeadDetailsDialog] =
    React.useState(false);
  const [allDropReasons, setAllDropReasons] = useState({});
  const [showConfetties, setShowConfetties] = useState(false);
  const [openFileUploads, setOpenFileUploads] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedEligbilityForm, setSelectedEligibilityForm] = useState([]);
  const [snoozedUntil, setSnoozedUntil] = useState('');
  const [callbackRequestActivity, setCallbackRequestActivity] = useState(false);
  // const [leadDropCheck, setLeadDropCheck] = useState(false);
  const [callDialledActivity, setCallDialledActivity] = useState('');
  const [notifyMe, setNotifyMe] = useState(false);
  const [selectedExternalTab, setSelectedExternalTab] = useState(4);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [showActivity, setShowActivity] = useState(true);
  const [expandedTabs, setExpandedTabs] = useState([true, true, true]);
  const [raisedDropRequestDetails, setRaisedDropRequestDetails] = useState([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const [activeLeadId, setActiveLeadId] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState({
    primary: '',
    secondary: {},
  });
  const [salesClouserPaymentsData, setSalesClouserPaymentsData] =
    useState(null);
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [callLog, setCallLog] = useState(null);
  const [selectedEfConversionFlow, setSelectedEfConversionFlow] = useState([]);
  const [cpeIsLoading, setCpeIsLoading] = useState(false);
  const [allHolidays, setAllHolidays] = useState([]);
  const [telecmiEnabledUsers, setTelecmiEnabledUserss] = useState(false);
  const [callHappening, setCallHappening] = useState(false);
  const [callStatus, setCallStatus] = useState(null);
  const [interectionBoxes, setInterectionBoxes] = useState(!currentAdminIsIC());
  const [isPolling, setIsPolling] = useState(false);
  const pollingRef = useRef(null);
  const [
    openFollowUpTimeConfirmationModal,
    setOpenFollowUpTimeConfirmationModal,
  ] = useState(false);
  const [showPayment, setShowPayment] = useState(0);
  const [showEFSBannerStatus, setShowEFSBannerStatus] = useState(false);

  const { allAdmins } = useAllAdmins();
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) || {};
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));
  const navigate = useNavigate();
  const {
    CREATED,
    ASSIGNED,
    ONE_STEP_TO_PAYMENT,
    COMPLETE_AMOUNT_PAID,
    REACTIVATED,
    DROPPED,
    DROPPED_POST_SALES_REFUND_INITIATED,
    DROPPED_POST_SALES_REFUND_DENIED,
    SCREENING_CALL_ATTENDED,
    TOKEN_AMOUNT_PAID,
  } = constants;

  //Currently commented will remove once new logic is stable
  const statusNames = [
    'created',
    'assigned',
    'connect_attempted',
    'answer_and_intro_done',
    'lead_qualification_done',
    'questioning_in_tect_done',
    'complete_program_explained',
    'eligibility_form_sent',
    'eligibility_form_filled',
    'intent_closure_call_done',
    'screening_call_scheduled',
    'screening_call_attended',
    'offer_letter_payment_link_sent',
    'token_amount_paid',
    'complete_amount_paid',
  ];

  let flagformorethanSixDnp = true;
  const ArrayofLeadStatuslength = currentLeadStatusActivities.length;
  const currentLeadStatus =
    currentLeadStatusActivities[ArrayofLeadStatuslength - 1]?.status;
  const indexOfCurrentStatus = statusNames.indexOf(currentLeadStatus);
  if (indexOfCurrentStatus > 14) {
    flagformorethanSixDnp = false;
  }

  // const indexOfCompleteExplained = statusNames.indexOf('complete_program_explained');

  const handleContactDetails = (currentTime, lastSyncTime) => {
    // if (!lastSyncTime) {
    setShowContactDetails(true);
    return;
    // }
    const timeDifference = (currentTime - lastSyncTime) / (1000 * 60);

    if (timeDifference < constants.THIRTY_MINUTES) {
      setShowContactDetails(true);
    } else {
      setShowContactDetails(false);
    }
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'callHappening') {
        setCallHappening(event.newValue === 'true');
      }
      if (event.key === 'callStatus') {
        setCallStatus(event.newValue);
      }
      if (event.key === 'hangupTimestamp') {
        checkHangupTime();
      }
    };

    const initialCallHappening =
      localStorage.getItem('callHappening') === 'true';
    const initialCallStatus = localStorage.getItem('callStatus');
    const initialHangupTimestamp = localStorage.getItem('hangupTimestamp');

    if (initialCallHappening) {
      setIsPolling(true);
      setInterectionBoxes(true);
    }

    setCallHappening(initialCallHappening);
    setCallStatus(initialCallStatus);
    if (initialHangupTimestamp) {
      checkHangupTime();
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isPolling) {
      pollingRef.current = setInterval(() => {
        getCurrentCallStatus();
      }, 5000);

      return () => clearInterval(pollingRef.current);
    }
  }, [isPolling]);

  const checkHangupTime = () => {
    const hangupTimestamp = localStorage.getItem('hangupTimestamp');
    if (hangupTimestamp) {
      const hangupTime = moment(hangupTimestamp);
      const currentTime = moment();
      const thirtyMinutes = moment.duration(30, 'minutes');

      if (currentTime.diff(hangupTime) >= thirtyMinutes.asMilliseconds()) {
        setInterectionBoxes(false);
        localStorage.removeItem('hangupTimestamp');
      } else {
        setInterectionBoxes(true); // Ensure the box is shown
        setTimeout(() => {
          setInterectionBoxes(false);
          localStorage.removeItem('hangupTimestamp');
        }, thirtyMinutes.asMilliseconds() - currentTime.diff(hangupTime));
      }
    } else {
      setInterectionBoxes(false);
    }
  };

  useEffect(() => {
    if (currentAdminIsIC()) {
      getCurrentIncomingCallStatus();
    }
  }, []);

  useEffect(() => {
    if (currentLead.status === constants.COMPLETE_PROGRAM_EXPLAINED) {
      fetchEFSBannerStatus(currentLead.id);
    }

    if (currentLead.status === constants.ELIGIBILITY_FORM_SENT) {
      setShowEFSBannerStatus(false);
    }

    return () => {};
  }, [currentLead.status, currentLead.id]);

  const fetchEFSBannerStatus = async (id) => {
    try {
      const res = await axios.get(
        `${base_url()}/api/users/${id}/show_efs_banner`
      );
      if (res.data?.showBanner) {
        setShowEFSBannerStatus(res.data?.showBanner);
      }
    } catch (error) {
      console.log(error, { error: error.message });

      setHasError(true);
      setErrorText('Error: Could not fetch EFS banner.');
    }
  };

  const getCurrentIncomingCallStatus = () => {
    axios
      .get(`${base_url()}/api/call-incoming-status`)
      .then((res) => {
        const currentStatus = res.data;
        if (currentStatus === 'answered') {
          setInterectionBoxes(true);
        }
        if (currentStatus === 'hangupWithAnswered') {
          const hangupTimestamp = moment().toISOString();
          localStorage.setItem('hangupTimestamp', hangupTimestamp);
          setInterectionBoxes(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorText(
          err.response?.data?.message || 'Could not fetch the call status.'
        );
      });
  };

  const getCurrentCallStatus = () => {
    axios
      .get(`${base_url()}/api/call-status`)
      .then((res) => {
        const currentStatus = res.data;
        const lastStatus = localStorage.getItem('callStatus');

        if (currentStatus !== lastStatus) {
          if (currentStatus === 'started') {
            setIsSuccess(true);
            setSuccessText('Call is ringing.');
            localStorage.setItem('callStatus', currentStatus);
            localStorage.setItem('callHappening', 'true');
            setCallHappening(true);
          }
          if (currentStatus === 'answered') {
            setIsSuccess(true);
            setSuccessText('Call is answered.');
            localStorage.setItem('callStatus', currentStatus);
            localStorage.setItem('callHappening', 'true');
            setCallHappening(true);
            setInterectionBoxes(true);
          }
          if (currentStatus === 'hangup' || (!currentStatus && lastStatus)) {
            const hangupTimestamp = moment().toISOString();
            if (lastStatus === 'answered') {
              localStorage.setItem('hangupTimestamp', hangupTimestamp);
            }

            localStorage.removeItem('callStatus');
            localStorage.removeItem('callHappening');
            setCallHappening(false);

            setInterectionBoxes(true);
            setIsSuccess(true);
            setSuccessText('Call has ended.');
            setIsPolling(false);
            setCallStatus(null);
            checkHangupTime();
            setTimeout(() => {
              updateCurrentLeadContexts();
            }, 2000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorText(
          err.response?.data?.message || 'Could not fetch the call status.'
        );
      });
  };

  const getAppLastSync = async (date) => {
    setIsLoading(true);
    let currentTime;
    try {
      const res = await axios.get(`${base_url()}/mobile/api/last-sync`);
      const lastSyncTime = new Date(res.data).getTime();
      if (date) {
        currentTime = new Date(date).getTime();
      } else {
        currentTime = new Date().getTime();
      }
      handleContactDetails(currentTime, lastSyncTime);
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setErrorText(
        error.response?.data?.message || 'Error: Could not sync with the app.'
      );
    }
  };
  const [socket] = useSocket();

  const filterIcsAndCheck = (message) => {
    if (parseInt(message.id) === currentAdmin.id) {
      getAppLastSync(message.date);
    }
  };

  const handleLastSync = (data) => {
    data.forEach((message) => {
      filterIcsAndCheck(message);
    });
  };

  const initialCheck = () => {
    getAppLastSync();
  };

  useEffect(() => {
    initialCheck();
    fetchAllHolidays();
    getTelecmiEnabledUsers();
    return () => {};
  }, []);

  useEffect(() => {
    if (selectedExternalTab === 4) {
      resetValue();
    }
  }, [selectedExternalTab]);

  useEffect(() => {
    if (socket) {
      socket.on('last_sync', (data) => handleLastSync(data));

      return () => {
        socket.off('last_sync', handleLastSync);
      };
    }
  }, [socket]);

  let dnpSnoozeTime = '';
  useEffect(() => {
    if (showConfetties) {
      setTimeout(() => {
        setShowConfetties(false);
      }, 7000);
    }
  }, [showConfetties]);

  useEffect(() => {
    const fetchDropReasons = () => {
      axios
        .get(
          `${base_url()}/api/users/drop_reasons?status=${currentLead.status}`
        )
        .then((res) => {
          if (res.data) {
            setAllDropReasons(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorText(
            err.response?.data?.message || "Couldn't fetch the drop reasons."
          );
        });
    };

    if (currentLead.status) {
      fetchDropReasons();
    }

    return () => {};
  }, [currentLead.status]);

  const updateRequestBody = {
    requested_callback: callbackRequestActivity,
    call_did_not_pick: callDialledActivity,
    notify_for_follow_up: notifyMe,
  };
  // Currently commented will remove once new logic is stable
  // const updateRequestBodyForLeaddrop = () => {
  //   updateRequestBody.assignee = 206;
  //   updateRequestBody.label = '6th DNP drop';
  // };

  useEffect(() => {
    leadDropRequest(currentLead.id);
  }, [currentLead.id]);

  // useEffect(() => {
  //   fetchPostSalesLeadData(currentLead.id);
  // }, [showPayment]);

  const leadDropRequest = async (id) => {
    try {
      const res = await axios.get(
        `${base_url()}/api/manager_actions/${id}/drop_request`
      );
      if (res.data !== null) {
        setRaisedDropRequestDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorText(
        error.response?.data?.message || "Couldn't fetch the drop request."
      );
    }
  };

  const handleCallbackRequest = (status) => {
    setCallbackRequestActivity(status);
    if (status && !notifyMe) {
      handleNotifyMe();
    }
  };

  const handleShowActivity = () => {
    setShowActivity(!showActivity);
  };

  // const handleLeadDropCheck = () => {
  //   setLeadDropCheck(!leadDropCheck);
  // };

  const handleCallDialledActivity = (e) => {
    setCallDialledActivity(e.target.value);
  };

  const handleNotifyMe = () => {
    setNotifyMe(!notifyMe);
  };

  const resetValue = () => {
    setInteractionInProgress(false);
    setNewInteractionNote('');
    setUploadedFiles([]);
    setSnoozedUntil('');
    setCallbackRequestActivity(false);
    setNotifyMe(false);
    setCallDialledActivity('');
    // setLeadDropCheck(false);
  };

  const updateCurrentLeadContexts = () => {
    getCurrentLeadActivitiesAndUpdateContext(currentLead.id);
    getCurrentLeadStatusActivitiesAndUpdateContext(currentLead.id);
    getCurrentLeadInteractionsAndUpdateContext(currentLead.lead_id);
    getCurrentLeadAndUpdateContext(currentLead.lead_id);
  };

  // Currently commented will remove once new logic is stable
  // const calculateDnpCount = (flagformorethanSixDnp, currentLeadActivities) => {
  //   let dnpcountoflead = 0;

  //   if (flagformorethanSixDnp) {
  //     const filteredLeadActivities = currentLeadActivities.filter(
  //       (obj) => obj.name !== 'snooze_update'
  //     );
  //     filteredLeadActivities?.forEach((obj) => {
  //       if (obj.name === 'did_not_pick') {
  //         if (obj.metadata === 'frequency:2') {
  //           dnpcountoflead += 2;
  //         } else {
  //           dnpcountoflead++;
  //         }
  //       } else {
  //         dnpcountoflead = 0;
  //       }
  //     });
  //   }

  //   return dnpcountoflead;
  // };

  const fetchAllHolidays = () => {
    axios
      .get(`${base_url()}/api/holidays`)
      .then((res) => {
        setAllHolidays(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorText(
          err.response?.data?.message || 'Could not fetch the holidays.'
        );
      });
  };

  const isHolidayOrLeave = (date) => {
    const holidays = allHolidays.filter((e) => e.type !== 'halfday_off');
    if (!holidays || holidays.length === 0 || !Array.isArray(holidays)) {
      return false;
    }
    const holidayDates = holidays.map((holiday) => holiday.date);
    const dateString = moment(date).format('YYYY-MM-DD');
    return holidayDates.includes(dateString);
  };

  const isHalfDay = (date) => {
    const momentDate = moment(date);
    const dayOfWeek = momentDate.day();
    return dayOfWeek === 1;
  };

  const isWithinBusinessHours = () => {
    const followUpTimeInHour = new Date(snoozedUntil).getHours();
    const halfDayFollowUpcheck =
      isHalfDay(snoozedUntil) &&
      (followUpTimeInHour < 11 || followUpTimeInHour >= 17);
    const fullDayFollowUpCheck =
      followUpTimeInHour < 11 || followUpTimeInHour >= 20;

    return (
      isHolidayOrLeave(snoozedUntil) ||
      halfDayFollowUpcheck ||
      fullDayFollowUpCheck
    );
  };

  const isWithin48Hours = () => {
    const followUpTime = moment(snoozedUntil);
    const currentDay = moment();
    const nextDay = moment().add(1, 'days');
    const next2Days = moment().add(2, 'days');

    let nextDayIsHoliday = isHolidayOrLeave(nextDay);
    let next2DaysIsHoliday = isHolidayOrLeave(next2Days);

    while (nextDayIsHoliday || next2DaysIsHoliday) {
      nextDay.add(1, 'days');
      next2Days.add(1, 'days');
      nextDayIsHoliday = isHolidayOrLeave(nextDay);
      next2DaysIsHoliday = isHolidayOrLeave(next2Days);
    }

    return (
      followUpTime.isSameOrAfter(currentDay) && followUpTime.isBefore(next2Days)
    );
  };

  const addInteraction = async (snoozeCheckForBusinessHour) => {
    if (uploadedFiles.length > 10) {
      setHasError(true);
      setErrorText('Maximum 10 files can be uploaded');
      return;
    }

    if (
      selectedExternalTab !== 1 &&
      selectedExternalTab !== 3 &&
      !newInteractionNote
    ) {
      setHasError(true);
      setErrorText("Interaction can't be empty");
      resetValue();
      return;
    }

    if (selectedExternalTab === 1 && !callDialledActivity) {
      setHasError(true);
      setErrorText('Please choose a call dial activity');
      resetValue();
      return;
    }

    if (!snoozedUntil && selectedExternalTab !== 1) {
      setHasError(true);
      setErrorText("Follow up time can't be empty");
      return;
    }

    if (
      snoozedUntil &&
      snoozedUntil < getTimeZoneFormattedTimestamp(new Date())
    ) {
      setHasError(true);
      setErrorText('Please choose future timestamp');
      return;
    }

    if (snoozedUntil) {
      const snoozedUntil0 = snoozedUntil.split('T').join(' ');
      const snoozedUntil1 = `${snoozedUntil0}+05:30`;
      const snoozeDateObject = moment(snoozedUntil1).utc();
      const currentTime = moment();
      const differenceInDays = snoozeDateObject.diff(currentTime, 'days');
      if (
        currentLead.status === constants.CONNECT_ATTEMPTED &&
        !isWithin48Hours()
      ) {
        setHasError(true);
        setErrorText(
          'Follow-ups cannot be scheduled more than 2 days at the CA stage.'
        );
        return;
      }
      if (differenceInDays > 5) {
        setHasError(true);
        setErrorText(
          'Follow-ups should be done in less than or equal five days.'
        );
        return;
      }

      if (!snoozeCheckForBusinessHour && isWithinBusinessHours()) {
        setOpenFollowUpTimeConfirmationModal(true);
        return;
      }
    }

    // Currently commented will remove once new logic is stable
    // this condition will check if the lead status is after CPE so normal work flow
    if (!flagformorethanSixDnp) {
      if (!snoozedUntil && selectedExternalTab !== 2) {
        setHasError(true);
        setErrorText("Follow up time can't be empty");
        resetValue();
        return;
      }
    }

    const searchDnp = () => {
      const textArray = ['dnp', 'cnc', 'dnc'];
      const lowercasedNewInteractionNote = newInteractionNote.toLowerCase();

      for (let i = 0; i < textArray.length; i++) {
        if (lowercasedNewInteractionNote.includes(textArray[i])) {
          return true;
        }
      }
      return false;
    };

    if (selectedExternalTab !== 1 && newInteractionNote && searchDnp()) {
      setNewInteractionNote('');
      setSnoozedUntil('');
      setSelectedExternalTab(1);
      return;
    }

    setInteractionInProgress(true);

    const postData = {
      notes: newInteractionNote.trim(),
      lead_id: currentLead.lead_id,
    };
    try {
      if (postData.notes) {
        const response = await axios.post(
          base_url() + '/api/users/' + currentLead.lead_id + '/interactions',
          postData
        );
        if (response.data?.id && uploadedFiles.length > 0) {
          const formBody = new FormData();
          uploadedFiles.forEach((file) => {
            formBody.append('interaction_files', file.data);
          });
          await axios.post(
            base_url() +
              '/api/interactions/' +
              response.data.id +
              '/files?context=interaction',
            formBody,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
      }
      if (snoozedUntil) {
        updateRequestBody.snoozed_until_1 = snoozedUntil;
      }

      // Currently commented will remove once new logic is stable
      // if (
      //   selectedExternalTab === 1 &&
      //   calculateDnpCount(flagformorethanSixDnp, currentLeadActivities) >= 6 &&
      //   flagformorethanSixDnp
      // ) {
      //   updateRequestBodyForLeaddrop();
      // }
      if (!snoozedUntil && selectedExternalTab === 1 && flagformorethanSixDnp) {
        const currentTime = new Date().getHours();
        let day = 'Today';
        if (currentTime >= 16 && new Date().getDay() !== 1) {
          day = 'Tomorrow';
        }
        updateSnoozedUntil(day, `${currentTime} PM`);
        updateRequestBody.snoozed_until_1 = dnpSnoozeTime;
      }

      if (callDialledActivity === 'Dialled Once') {
        updateRequestBody.frequency = 1;
      } else {
        updateRequestBody.frequency = 2;
      }

      if (
        currentLead.status === constants.ASSIGNED &&
        (selectedExternalTab === 1 || selectedExternalTab === 3)
      ) {
        await axios.post(
          base_url() + '/api/users/' + currentLead.lead_id + '/dnp',
          postData
        );
      }

      await axios.put(
        base_url() + '/api/users/' + currentLead.lead_id,
        updateRequestBody
      );
      setIsSuccess(true);
      setSuccessText('Updated');
      // Currently commented will remove once new logic is stable
      // {
      //   selectedExternalTab === 1 &&
      //   flagformorethanSixDnp === true &&
      //   calculateDnpCount(flagformorethanSixDnp, currentLeadActivities) >= 6
      //     ? setSuccessText('Lead reassigned due to exceeding 6 DNP marks')
      //     : setSuccessText('Updated');
      // }
      resetValue();
      updateCurrentLeadContexts();
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorText(
        err.response?.data?.message ?? 'Something went wrong, fetch failed'
      );
    } finally {
      resetValue();
      updateCurrentLeadContexts();
      setSelectedExternalTab(4);
    }
  };

  const getTelecmiEnabledUsers = () => {
    axios
      .get(base_url() + '/api/telecmi_users')
      .then((response) => {
        if (response?.data) {
          setTelecmiEnabledUserss(response?.data);
        }
      })
      .catch((error) => {
        setHasError(true);
        setErrorText(
          error.response?.data?.message ??
            'Something went wrong, fetch cmi users'
        );
        console.error('error in fetching telecmi users', error);
      });
  };

  const detailsNotAvailable = () => {
    return (
      <span
        style={{
          color: '#cecece',
        }}
      >
        Unavailable
      </span>
    );
  };

  const detailsHidden = () => {
    return (
      <span
        style={{
          color: '#ffcccb',
        }}
      >
        Hidden
      </span>
    );
  };

  const showContactsDetails = () => {
    return (
      <>
        {isLoading ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              color: 'grey',
            }}
          >
            {' '}
            <CircularProgress
              sx={{ color: 'silver' }}
              size={20}
            />{' '}
            Loading ...
          </span>
        ) : (
          <span
            style={{
              color: '#ffcccb',
            }}
          >
            Please refresh your app
          </span>
        )}
      </>
    );
  };

  const snoozeDetails = function () {
    if (!currentLead.snoozed_until_1) return detailsNotAvailable();

    return (
      <Stack
        direction={'row'}
        spacing={2}
        alignItems='center'
      >
        <Typography
          p={1}
          sx={{
            backgroundColor: '#fab005',
            width: 'fit-content',
            borderRadius: '10px',
            color: '#fff',
            lineHeight: '1rem',
          }}
          display='flex'
          alignItems='center'
          gap='7px'
          fontSize='1rem'
        >
          {getFormattedTimestamp(new Date(currentLead.snoozed_until_1))}
        </Typography>
        {currentLead.has_scheduled_follow_up && <NotificationsActiveIcon />}
      </Stack>
    );
  };

  const getLinkedinLink = () => {
    if ((currentLead?.linkedin_link ?? '') === '') {
      return detailsNotAvailable();
    } else if (currentLead?.linkedin_link === 'Hidden') {
      return detailsHidden();
    }
    if (!showContactDetails) return showContactsDetails();
    const link = `https://www.linkedin.com/in/${currentLead?.linkedin_link}`;
    return (
      <a
        style={{ marginLeft: 5 }}
        href={link}
        target='_blank'
      >
        {currentLead?.linkedin_link}
      </a>
    );
  };

  function getCollegeText() {
    if (
      !currentLead.branch &&
      !currentLead.graduation_year &&
      !currentLead.college
    ) {
      return detailsNotAvailable();
    }

    let text = '';
    if (currentLead.branch || currentLead.graduation_year) {
      text += currentLead.branch;
      text += ' ';
      text += currentLead.graduation_year;
    }

    if (currentLead.college) {
      text += ' graduate from ' + currentLead.college;
    }

    // required when branch is not present
    text = text.trim();

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  function getEmploymentText() {
    if (!currentLead.experience && !currentLead.role && !currentLead.company) {
      return detailsNotAvailable();
    }

    // There might be cases where the experience is not set to working_professional
    // but the role and company fields are present.
    let text = '';

    if (currentLead.experience != 'working_professional') {
      text += currentLead.experience + '. ';
    }

    if (currentLead.role || currentLead.company) {
      text += 'Works';
      text += currentLead.role ? ' as ' + currentLead.role : '';
      text += currentLead.company ? ' at ' + currentLead.company : '';
    } else if (currentLead.experience == 'working_professional') {
      text += 'Working professional';
    }

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  const getAssigneeText = () => {
    if (!currentLead?.assignee) {
      return (
        <Chip
          label={'No assignee'}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      );
    }

    const { fname, lname } = adminMap[currentLead?.assignee];

    return (
      <>
        <Chip
          label={[fname, lname || ''].join(' ')}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      </>
    );
  };
  const getProductText = () => {
    return (
      <>
        {currentLead.product && <>Product&nbsp;</>}
        <span>
          {!currentLead.product || currentLead.product === ''
            ? detailsNotAvailable()
            : splitByUnderscoresAndCapitalize(currentLead.product)}
        </span>
      </>
    );
  };

  const getLeadSourceTypeText = () => {
    return (
      <span
        style={{
          color: 'black',
          fontWeight: 400,
          textTransform: 'capitalize',
        }}
      >
        {currentLead?.lead_source_type || detailsNotAvailable()}
      </span>
    );
  };

  let emailCopyBtn = CopyIcon(currentLead.email);
  const getEmailText = () => {
    if (!currentLead.email) return detailsNotAvailable();
    else if (currentLead.email === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {currentLead.email} {emailCopyBtn}
      </div>
    );
  };

  let MobileCopyBtn = CopyIcon(currentLead.mobile);
  const getMobileText = () => {
    if (!currentLead.mobile) return detailsNotAvailable();
    if (currentLead.mobile === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '2px',
          fontWeight: '500',
          fontSize: '20px',
        }}
      >
        {currentLead.mobile} {MobileCopyBtn}
      </div>
    );
  };

  let whatsappCopyBtn = CopyIcon(currentLead.whatsapp);
  const getWhatsappText = () => {
    if (!currentLead.whatsapp) return detailsNotAvailable();
    if (currentLead.whatsapp === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '2px',
          fontWeight: '500',
          fontSize: '20px',
        }}
      >
        {currentLead.whatsapp} {whatsappCopyBtn}
      </div>
    );
  };

  const getExperienceText = () => {
    if (!currentLead.experience) return detailsNotAvailable();
    return <div>{currentLead.experience}</div>;
  };

  const getTechStackText = () => {
    if (!currentLead.tech_stack) return detailsNotAvailable();
    return <div>{currentLead.tech_stack}</div>;
  };

  const getBranchText = () => {
    if (!currentLead.branch) return detailsNotAvailable();
    return <div>{currentLead.branch}</div>;
  };

  const getGradutionYearText = () => {
    if (!currentLead.graduation_year) return detailsNotAvailable();
    return <div>{currentLead.graduation_year}</div>;
  };

  const getRoleText = () => {
    if (!currentLead.role) return detailsNotAvailable();
    return <div>{currentLead.role}</div>;
  };

  const getCompanyText = () => {
    if (!currentLead.company) return detailsNotAvailable();
    return <div>{currentLead.company}</div>;
  };

  const getCreatedAtDate = () => {
    if (!currentLead.created_at) return detailsNotAvailable();
    return (
      <div>
        {getFormattedTimestamp(new Date(Date.parse(currentLead.created_at)))}
      </div>
    );
  };

  const getLabelInfo = () => {
    if (!currentLead.label) return detailsNotAvailable();
    return <div>{currentLead.label}</div>;
  };

  const openLeadDetailsDialogForm = () => {
    setLeadDetailsDialog(!openLeadDetailsDialog);
    setOpenEditLeadDetailsDialog(!openEditLeadDetailsDialog);
  };

  const handleEditLeadDetailsDialogClose = useCallback((val) => {
    setOpenEditLeadDetailsDialog(val);
    setLeadDetailsDialog(!openLeadDetailsDialog);
  }, []);

  function ProfileDetailsTableView({
    openLeadDetailsDialogForm,
    profileDetails,
    showPayment,
    setShowPayment,
    // raisedDropRequestDetails,
  }) {
    function getRow(label, value) {
      if (label === '_divide_') {
        return (
          <TableRow
            sx={{
              border: '2px solid #495057',
              marginTop: '1px',
            }}
          />
        );
      }

      // if (hideFrom.includes(getCurrentAdminRole()) || !value) {
      //   return <></>;
      // }
      return (
        <TableRow key={label}>
          <TableCell
            sx={{
              width: '30%',
              padding: '5px 10px',
              border: '1px solid #e0e0e0',
              fontSize: '1rem',
              p: 1,
            }}
          >
            {label}
          </TableCell>
          <TableCell
            sx={{
              width: '70%',
              padding: '5px 10px',
              border: '1px solid #e0e0e0',
              fontSize: '1rem',
              p: 1,
            }}
          >
            {value}
          </TableCell>
        </TableRow>
      );
    }

    function getEditIcon(openLeadDetailsDialogForm) {
      return (
        <IconButton
          onClick={openLeadDetailsDialogForm}
          size='medium'
          sx={{
            float: 'right',
            mr: 1,
            color: 'black',
            display: 'inline-table',
          }}
          disabled={raisedDropRequestDetails.length !== 0}
        >
          <EditIcon />
        </IconButton>
      );
    }

    const shouldDisplayLeadExiprationText = (currentAdmin, currentLead) => {
      if (
        Object.keys(currentAdmin).length === 0 ||
        currentLead.status === 'dropped'
      ) {
        return false;
      }
      if (currentLead.assignee === currentAdmin.id || currentAdminIsAdmin()) {
        return true;
      }

      const { subordinate_admin_ids = [] } = currentAdmin;
      if (subordinate_admin_ids.includes(currentLead.assignee)) {
        return true;
      }
      return false;
    };

    const LeadExpirationLabel = (props) => {
      const properties = {
        label:
          "This lead's lifecycle is over. You can continue to work on it but the lead may get unassigned anytime.",
        color: 'red',
      };
      const now = new Date();
      let leadAssignedTimestamp = undefined;
      let leadExpirationTimestamp = undefined;

      props.currentLeadActivities.forEach((activity) => {
        if (activity.name === 'assignee_update' && activity.metadata !== null) {
          leadAssignedTimestamp = new Date(Date.parse(activity.created_at));
          leadExpirationTimestamp = addDays(leadAssignedTimestamp, 10);
        }
      });

      if (leadAssignedTimestamp) {
        if (subtractDays(leadExpirationTimestamp, 3) > now) {
          properties.color = 'blue';
          properties.label = `This lead will be unassigned in ${getTimePeriod(
            leadExpirationTimestamp,
            now
          )} days`;
        } else if (
          subtractDays(leadExpirationTimestamp, 3) <= now &&
          now < subtractDays(leadExpirationTimestamp, 1)
        ) {
          const period = getTimePeriod(leadExpirationTimestamp, now);
          properties.color = '#f57c00'; // orange
          properties.label = `This lead will be unassigned in ${period} day${
            period > 1 ? 's' : ''
          }`;
        } else if (
          subtractDays(leadExpirationTimestamp, 1) <= now &&
          now <= leadExpirationTimestamp
        ) {
          const period = getTimePeriod(leadExpirationTimestamp, now, true);
          if (period >= 1) {
            // greater than 1 hour
            properties.label = `This lead will be unassigned in ${Math.floor(
              period
            )} hours`;
          } else if (period >= 0.0166) {
            // greater than 1 minute, but less than 1 hour
            properties.label = `This lead will be unassigned in ${Math.floor(
              period * 60
            )} minutes`;
          } else if (period > 0) {
            // greater than 0 seconds, but less than 1 minute
            properties.label = `This lead will be unassigned in ${Math.floor(
              period * 60 * 60
            )} seconds`;
          }
        }
      }

      return (
        <>
          {leadAssignedTimestamp && now >= leadAssignedTimestamp ? (
            <Stack
              direction='row'
              spacing={0.5}
              sx={{ color: properties.color, mb: 1 }}
            >
              <AccessTimeIcon />
              <Typography
                varian
                t='body1'
                component='p'
              >
                {properties.label}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </>
      );
    };

    const handleMakeCall = () => {
      const leadDetail = {
        name: currentLead?.fname,
        to_number: Number(currentLead?.mobile),
      };

      axios
        .post(base_url() + '/api/telecmi_initiate_call', leadDetail)
        .then((response) => {
          if (response?.data?.success) {
            setIsSuccess(true);
            setSuccessText('Call is initiated.');
            setIsPolling(true);
            setCallHappening(true);
            localStorage.setItem('callHappening', 'true');
          }
        })
        .catch((error) => {
          setHasError(true);
          setErrorText(
            error.response?.data?.message ??
              'Something went wrong, call can not be initiated'
          );
        });
    };

    const handleTabChange = (e, newTabValue) => {
      setShowPayment(newTabValue);
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: ' flex-start',
        }}
      >
        {telecmiEnabledUsers &&
          window.location.host === constants.SALES1_HOST_NAME && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                minWidth: '354px',
                width: '90%',
              }}
            >
              <Button
                sx={{
                  mb: 2,
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  backgroundColor: 'green',
                  '&:hover': { backgroundColor: 'darkgreen' },
                }}
                variant='contained'
                onClick={handleMakeCall}
                disabled={callHappening}
              >
                <CallIcon />
                Dial Now
              </Button>
            </div>
          )}
        {currentLead?.is_hot ? <CurrentHotLeadWarningBox /> : ''}
        <Table sx={{ width: '90%' }}>
          <TableHead>
            <TableRow sx={{ border: '1px solid #e0e0e0' }}>
              <TableCell
                colSpan={2}
                display={'flex'}
                justifyContent='space-between'
              >
                {showContactDetails ? (
                  <Box
                    component='span'
                    // height={20}
                  >
                    <Box
                      height={showPayment ? 35 : 5}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <Tabs
                        value={showPayment}
                        onChange={handleTabChange}
                      >
                        <Tab label='Profile' />
                        {/* {leadData?.isPayments && <Tab label='Payment' />} */}
                      </Tabs>
                      {/* <Typography
                        fontSize={'20px'}
                        display={'inline-table'}
                        sx={{
                          cursor: 'pointer',
                          color:
                            showPayment || !leadData?.isPayments ? '' : 'blue',
                        }}
                        onClick={() => setShowPayment(0)}
                      >
                        Profile
                      </Typography>
                      {leadData?.isPayments && (
                        <Typography
                          fontSize={'20px'}
                          display={'inline-table'}
                          sx={{
                            cursor: 'pointer',
                            color: showPayment ? 'blue' : '',
                          }}
                          onClick={() => setShowPayment(1)}
                        >
                          Payment
                        </Typography>
                      )} */}
                    </Box>
                    {!showPayment && getEditIcon(openLeadDetailsDialogForm)}
                  </Box>
                ) : (
                  'Please refresh your app'
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shouldDisplayLeadExiprationText(currentAdmin, currentLead) && (
              <TableRow sx={{ border: '1px solid #e0e0e0' }}>
                <TableCell
                  sx={{
                    border: '1px solid #e0e0e0',
                    fontSize: '1rem',
                  }}
                >
                  Lifecycle
                </TableCell>
                <TableCell
                  display={'flex'}
                  justifyContent='space-between'
                >
                  <Typography
                    fontSize={'20px'}
                    display={'inline-table'}
                  >
                    <LeadExpirationLabel
                      currentLead={currentLead}
                      currentLeadActivities={currentLeadActivities}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {profileDetails.map((row) => getRow(row.label, row.value))}
          </TableBody>
        </Table>
      </div>
    );
  }

  const interactionPlaceholderText =
    'You can highlight text using a colon.\nEg: "Current CTC: 5 LPA" will highlight "Current CTC" in a different color';
  let copyText = [];
  if (currentLead.fname) {
    copyText.push(currentLead.fname);
  }
  if (currentLead.lname) {
    copyText.push(currentLead.lname);
  }

  copyText.push('#' + currentLead.lead_id);
  copyText = copyText.join(' ');
  let LeadIdCopyIcon = CopyIcon(copyText);

  const profileDetails = [
    {
      label: 'Email',
      value: getEmailText(),
    },
    {
      label: 'Phone',
      value: getMobileText(),
    },
    {
      label: 'Whatsapp',
      value: getWhatsappText(),
    },
    {
      label: 'Linkedin',
      value: getLinkedinLink(),
    },
    {
      label: '_divide_',
    },
    {
      label: 'Experience',
      value: getExperienceText(),
    },
    {
      label: 'Tech stack',
      value: getTechStackText(),
    },
    {
      label: 'Branch',
      value: getBranchText(),
    },
    {
      label: 'Graduation year',
      value: getGradutionYearText(),
    },
    {
      label: 'Role',
      value: getRoleText(),
    },
    {
      label: 'Company',
      value: getCompanyText(),
    },
    {
      label: 'College',
      value: getCollegeText(),
    },
    {
      label: 'Employment',
      value: getEmploymentText(),
    },
    {
      label: '_divide_',
    },
    {
      label: 'Created at',
      value: getCreatedAtDate(),
    },
    {
      label: 'Lead source type',
      value: getLeadSourceTypeText(),
    },
    {
      label: 'Next follow up',
      value: snoozeDetails(),
    },
    {
      label: 'Product',
      value: getProductText(),
    },
    {
      label: 'Label',
      value: getLabelInfo(),
    },
    {
      label: 'Assigned to',
      value: getAssigneeText(),
    },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleExternalTabs = (_, newValue) => {
    setSelectedExternalTab(newValue);
  };

  const updateSnoozedUntil = (day, value) => {
    let date = new Date();

    // Check if the selected tab is for interactions
    if (selectedExternalTab === 1 && flagformorethanSixDnp === true) {
      switch (day) {
        case 'Today':
          if (new Date().getHours() < 13) {
            date.setHours(16);
            date.setMinutes(0);
          } else if (new Date().getHours() < 16) {
            date.setHours(19);
            date.setMinutes(0);
          } else {
            if (date.getDay() === 1 && date.getHours() >= 16) {
              // Check if it's Monday and time is 16:00
              date.setDate(date.getDate() + 2);
            } else {
              date.setDate(date.getDate() + 1);
            }
            date.setHours(13);
            date.setMinutes(0);
          }
          break;

        case 'Tomorrow':
          date.setDate(date.getDate() + 1);
          date.setHours(13);
          date.setMinutes(0);
          break;

        default:
          date = new Date(value);
          break;
      }
    } else {
      // If selected tab is not for interactions, set snooze time as per usual flow
      switch (day) {
        case 'Today':
          switch (value) {
            case '12 PM':
              date.setMinutes(0);
              date.setHours(12);
              break;
            case '3 PM':
              date.setMinutes(0);
              date.setHours(15);
              break;
            case '6 PM':
              date.setMinutes(0);
              date.setHours(18);
              break;
            case '9 PM':
              date.setMinutes(0);
              date.setHours(21);
              break;
          }
          break;

        case 'Tomorrow':
          switch (value) {
            case '9 AM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(9);
              break;
            case '12 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(12);
              break;
            case '6 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(18);
              break;
            case '9 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(21);
              break;
          }
          break;

        default:
          date = new Date(value);
          break;
      }
    }
    dnpSnoozeTime = getTimeZoneFormattedTimestamp(date);
    setSnoozedUntil(getTimeZoneFormattedTimestamp(date));
  };

  const callButtonClickHandler = () => {
    const leadId = currentLead.id;
    navigate(`/leads/${leadId}/call`, {
      state: {
        currentLead,
        currentLeadStatusActivities,
        currentLeadSignups,
        currentLeadInteractions,
      },
    });
  };

  const excludedStatuses = [
    CREATED,
    ONE_STEP_TO_PAYMENT,
    COMPLETE_AMOUNT_PAID,
    REACTIVATED,
    DROPPED,
    DROPPED_POST_SALES_REFUND_INITIATED,
    DROPPED_POST_SALES_REFUND_DENIED,
  ];

  const shouldShowButton =
    !excludedStatuses.includes(currentLead.status) &&
    (currentAdminIsAdmin() || currentAdminIsManager());

  const isTimeToChangePositonOfCallingButton =
    currentLead.status === SCREENING_CALL_ATTENDED;
  return (
    <>
      {showConfetties && <ConfettiEffect />}
      {hasError && (
        <ErrorNotifier
          message={errorText}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successText}
          setIsSuccess={setIsSuccess}
        />
      )}
      {openFileUploads && (
        <InteractionFileUploads
          {...{
            openFileUploads,
            setOpenFileUploads,
            uploadedFiles,
            setUploadedFiles,
          }}
        />
      )}

      {selectedEligbilityForm.length > 0 && (
        <EligibilityFormDialog
          {...{
            selectedEligbilityForm,
            setSelectedEligibilityForm,
            selectedEfConversionFlow,
            setSelectedEfConversionFlow,
          }}
        />
      )}
      {openFollowUpTimeConfirmationModal && (
        <FollowUpTimeConfirmationModal
          {...{
            openFollowUpTimeConfirmationModal,
            setOpenFollowUpTimeConfirmationModal,
            addInteraction,
          }}
        />
      )}

      <Grid
        container
        spacing={1}
        p={2}
        pr={0}
        height={'fit-content'}
        style={{ overflow: 'hidden' }}
      >
        <Grid
          item
          xs={4}
          style={{
            borderRight: '2px solid grey',
            height: '85vh',
            overflow: 'auto',
            paddingBottom: leadFocusMode ? '160px' : '70px',
          }}
        >
          {!openEditLeadDetailsDialog && (
            <Stack spacing={1}>
              <Typography
                variant='h5'
                fontWeight={500}
                mb={2}
                color='error'
              >
                {currentLead.is_blacklisted
                  ? 'This lead is not supposed to be contacted'
                  : ''}
              </Typography>

              <ProfileDetailsTableView
                openLeadDetailsDialogForm={openLeadDetailsDialogForm}
                profileDetails={profileDetails}
                showPayment={showPayment}
                setShowPayment={setShowPayment}
                //   raisedDropRequestDetails={raisedDropRequestDetails}
              />
            </Stack>
          )}

          {openEditLeadDetailsDialog ? (
            <LeadEditForm
              openModal={openEditLeadDetailsDialog}
              setOpenModal={handleEditLeadDetailsDialogClose}
              setShowConfetties={setShowConfetties}
              allDropReasons={allDropReasons}
              setAllDropReasons={setAllDropReasons}
            />
          ) : (
            <>
              <LeadCommunications />
            </>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            height: leadFocusMode ? '56vh' : '85vh',
            overflow: 'auto',
          }}
        >
          {showPayment ? (
            <PostSalesPaymentTab />
          ) : (
            <Stack
              spacing={2}
              sx={{
                padding: '10px 10px',
                position: 'relative',
              }}
            >
              {shouldShowButton && (
                <Box
                  sx={{
                    position: 'absolute',
                    mt: isTimeToChangePositonOfCallingButton
                      ? ''
                      : '2rem !important',
                  }}
                  onClick={callButtonClickHandler}
                >
                  <CallButton />
                </Box>
              )}
              {raisedDropRequestDetails.length === 0 &&
              raisedDropRequestDetails !== null ? (
                <>
                  <PartialViewLeadEditForm
                    salesClouserPaymentsData={salesClouserPaymentsData}
                    setSalesClouserPaymentsData={setSalesClouserPaymentsData}
                    setShowConfetties={setShowConfetties}
                    allDropReasons={allDropReasons}
                    setAllDropReasons={setAllDropReasons}
                    raisedDropRequestDetails={raisedDropRequestDetails}
                    selectedReasons={selectedReasons}
                    setSelectedReasons={setSelectedReasons}
                    leadDropRequest={leadDropRequest}
                  />
                  {/* check status and block on CPE */}
                  {showEFSBannerStatus && (
                    <>
                      <SendEligibilityFormBanner currentLead={currentLead} />
                    </>
                  )}
                  <Box>
                    {selectedExternalTab === 4 && (
                      <Box
                        sx={{
                          marginTop: 0,
                          padding: 2,
                          backgroundColor: '#EBF8FF',
                          borderBottomLeftRadius: '5px',
                          borderBottomRightRadius: '5px',
                        }}
                      >
                        {(!telecmiEnabledUsers || interectionBoxes) && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                            }}
                          >
                            <Box
                              sx={{
                                boxShadow: 1,
                                width: '23rem',
                                height: '3rem',
                                bgcolor: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? '#101010'
                                    : '#fff',
                                color: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? 'grey.300'
                                    : 'grey.800',
                                p: 1,
                                m: 1,
                                borderRadius: 2,
                                textAlign: 'center',
                                display: 'flex',
                                direction: 'row',
                                cursor: 'pointer',
                              }}
                              onClick={() => setSelectedExternalTab(0)}
                            >
                              <ForumIcon
                                sx={{
                                  marginTop: '0.4rem',
                                  marginLeft: '1rem',
                                }}
                              />
                              <Typography
                                sx={{
                                  marginTop: '0.3rem',
                                  fontWeight: 500,
                                  marginLeft: '1rem',
                                  fontSize: '1.1rem',
                                }}
                              >
                                Detailed Interaction
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                boxShadow: 1,
                                width: '23rem',
                                height: '3rem',
                                bgcolor: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? '#101010'
                                    : '#fff',
                                color: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? 'grey.300'
                                    : 'grey.800',
                                p: 1,
                                m: 1,
                                borderRadius: 2,
                                textAlign: 'center',
                                display: 'flex',
                                direction: 'row',
                                cursor: 'pointer',
                              }}
                              onClick={() => setSelectedExternalTab(3)}
                            >
                              <PhoneCallbackIcon
                                sx={{
                                  marginTop: '0.4rem',
                                  marginLeft: '1rem',
                                }}
                              />
                              <Typography
                                sx={{
                                  marginTop: '0.3rem',
                                  fontWeight: 500,
                                  marginLeft: '1rem',
                                  fontSize: '1.1rem',
                                }}
                              >
                                Requested Callback (RCB)
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Box
                            sx={{
                              boxShadow: 1,
                              width: '23rem',
                              height: '3rem',
                              bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                  ? '#101010'
                                  : '#fff',
                              color: (theme) =>
                                theme.palette.mode === 'dark'
                                  ? 'grey.300'
                                  : 'grey.800',
                              p: 1,
                              m: 1,
                              borderRadius: 2,
                              textAlign: 'center',
                              display: 'flex',
                              direction: 'row',
                              cursor: 'pointer',
                            }}
                            onClick={() => setSelectedExternalTab(2)}
                          >
                            <ModeCommentIcon
                              sx={{
                                marginTop: '0.4rem',
                                marginLeft: '1rem',
                              }}
                            />
                            <Typography
                              sx={{
                                marginTop: '0.3rem',
                                fontWeight: 500,
                                marginLeft: '1rem',
                                fontSize: '1.1rem',
                              }}
                            >
                              Social Media Interaction
                            </Typography>
                          </Box>
                          {!telecmiEnabledUsers ? (
                            <Box
                              sx={{
                                boxShadow: 1,
                                width: '23rem',
                                height: '3rem',
                                bgcolor: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? '#101010'
                                    : '#fff',
                                color: (theme) =>
                                  theme.palette.mode === 'dark'
                                    ? 'grey.300'
                                    : 'grey.800',
                                p: 1,
                                m: 1,
                                borderRadius: 2,
                                textAlign: 'center',
                                display: 'flex',
                                direction: 'row',
                                cursor: 'pointer',
                              }}
                              onClick={() => setSelectedExternalTab(1)}
                            >
                              <PhoneMissedIcon
                                sx={{
                                  marginTop: '0.4rem',
                                  marginLeft: '1rem',
                                }}
                              />
                              <Typography
                                sx={{
                                  marginTop: '0.3rem',
                                  fontWeight: 500,
                                  marginLeft: '1rem',
                                  fontSize: '1.1rem',
                                }}
                              >
                                Did not pick (DNP)
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: '23rem',
                                height: '3rem',
                                p: 1,
                                m: 1,
                              }}
                            ></Box>
                          )}
                        </Box>
                      </Box>
                    )}

                    {selectedExternalTab !== 4 && (
                      <ExternalTabComponents
                        salesClouserPaymentsData={salesClouserPaymentsData}
                        setSalesClouserPaymentsData={
                          setSalesClouserPaymentsData
                        }
                        flagformorethansixdnp={flagformorethanSixDnp}
                        selectedExternalTab={selectedExternalTab}
                        setSelectedExternalTab={setSelectedExternalTab}
                        handleCallbackRequest={handleCallbackRequest}
                        callbackRequestActivity={callbackRequestActivity}
                        newInteractionNote={newInteractionNote}
                        setNewInteractionNote={setNewInteractionNote}
                        updateSnoozedUntil={updateSnoozedUntil}
                        snoozedUntil={snoozedUntil}
                        handleNotifyMe={handleNotifyMe}
                        notifyMe={notifyMe}
                        handleCallDialledActivity={handleCallDialledActivity}
                        addInteraction={addInteraction}
                        interactionPlaceholderText={interactionPlaceholderText}
                        setOpenFileUploads={setOpenFileUploads}
                        interactionInProgress={interactionInProgress}
                        openFileUploads={openFileUploads}
                        callDialledActivity={callDialledActivity}
                        uploadedFiles={uploadedFiles}
                        // leadDropCheck={leadDropCheck}
                        // handleLeadDropCheck={handleLeadDropCheck}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      textAlign: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      width: '100%',
                      padding: '0 20px 0 0',
                    }}
                  >
                    {!showActivity &&
                      (currentAdminIsAdmin() || currentAdminIsManager()) &&
                      (isLoading ? (
                        <Button>Loading...</Button>
                      ) : (
                        <Button
                          onClick={() =>
                            fetchCallLogs(
                              currentLead,
                              setIsLoading,
                              setHasError,
                              setErrorText,
                              setIsSuccess,
                              setSuccessText,
                              setCallLog
                            )
                          }
                        >
                          Load Call Logs
                        </Button>
                      ))}
                    {!showActivity && (
                      <Button
                        onClick={() =>
                          getCPEDetails(
                            currentLead,
                            setCpeIsLoading,
                            setHasError,
                            setErrorText,
                            setIsSuccess,
                            setSuccessText,
                            setCurrentLead
                          )
                        }
                        disabled={cpeIsLoading}
                      >
                        {cpeIsLoading ? 'Fetching' : 'Load CPE Details'}
                      </Button>
                    )}
                    <Checkbox
                      sx={{ marginY: 1, padding: 0 }}
                      {...label}
                      onChange={handleShowActivity}
                      checked={showActivity}
                    />
                    <Typography
                      sx={{
                        marginLeft: '10px',
                        fontSize: '15px',
                      }}
                    >
                      Show external activities only
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {raisedDropRequestDetails.length > 0 &&
                    Object.keys(allDropReasons).length > 0 && (
                      <LeadDropRequestDetails
                        raisedDropRequestDetails={raisedDropRequestDetails}
                        {...{
                          reasons: allDropReasons,
                          selectedReasons,
                          setSelectedReasons,
                          leadDropRequest,
                        }}
                      />
                    )}
                </>
              )}
              {showActivity ? (
                <InteractionsList
                  adminMap={adminMap}
                  setHasError={setHasError}
                  setErrorText={setErrorText}
                  setSelectedEligibilityForm={setSelectedEligibilityForm}
                  setSelectedEfConversionFlow={setSelectedEfConversionFlow}
                  selectedEfConversionFlow={selectedEfConversionFlow}
                  showActivity={showActivity}
                />
              ) : (
                <InteractionsList
                  callLog={callLog}
                  adminMap={adminMap}
                  setHasError={setHasError}
                  setErrorText={setErrorText}
                  setSelectedEligibilityForm={setSelectedEligibilityForm}
                  setSelectedEfConversionFlow={setSelectedEfConversionFlow}
                  selectedEfConversionFlow={selectedEfConversionFlow}
                  showActivity={showActivity}
                />
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export const getTechStackProperties = (type) => {
  let color = '';
  let tooltipMessage = '';

  if (type === 'tech_background') {
    color = 'green';
    tooltipMessage = 'Lead has a tech background';
  } else if (type === 'non_tech_background') {
    color = 'red';
    tooltipMessage = 'Lead does not have a tech background';
  } else {
    color = 'gray';
    tooltipMessage =
      "Information required to verify the lead's tech background is missing";
  }

  return { color, tooltipMessage };
};
