import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { base_url } from '../../components/Mode';
import axios from 'axios';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { Button } from '@mui/material';
import ReshuffleModal from './Reshuffle/ReshuffleModal';

const DisableAccountPage = () => {
  const [disabledAccounts, setDisabledAccounts] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reshuffle, setReshuffle] = useState([]);
  const [openReshuffleModal, setOpenReshuffleModal] = useState(false);
  const [reshuffledLeads, setReshuffledLeads] = useState([]);
  const [obj, setobj] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDisabledAccounts();
    console.log('reshuffle', reshuffle);
    console.log('disabledAccounts', disabledAccounts);
    return () => {};
  }, []);

  const fetchDisabledAccounts = async () => {
    try {
      const url = `${base_url()}/api/disable-accounts`;
      const res = await axios.get(url);
      const data = res.data;

      // Group leads by admin
      const groupedData = Object.values(
        data.reduce((acc, item, index) => {
          const { admin_id, full_name, access_type, role, lead_id } = item;

          if (!acc[admin_id]) {
            acc[admin_id] = {
              id: admin_id,

              full_name,
              access_type,
              role,
              lead_count: 0,
              leads: [],
            };
          }

          acc[admin_id].lead_count += 1;
          acc[admin_id].leads.push(lead_id);

          return acc;
        }, {})
      ).map((admin, index) => ({
        ...admin,
        rowNumber: index + 1,
        leads: admin.leads.join(', '),
      }));
      if (res.data.length > 0) {
        const data = res.data.map((e) => {
          return { lead_id: e.lead_id, role: e.role, ic_id: e.admin_id };
        });
        setReshuffle(data);
      } else {
        setReshuffle([]);
      }
      setDisabledAccounts(groupedData);
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(`Error fetching disable accounts`);
    }
    console.log('reshuffle', reshuffle);
    console.log('disabledAccounts', disabledAccounts);
  };

  const columns = [
    { field: 'rowNumber', headerName: 'No.', width: 90 },
    { field: 'full_name', headerName: 'Full Name', width: 200 },
    { field: 'access_type', headerName: 'Access Type', width: 150 },
    { field: 'lead_count', headerName: 'Lead Count', width: 130 },
    { field: 'leads', headerName: 'Leads', width: 730 },
  ];

  function reShuffleLeads() {
    axios
      .post(`${base_url()}/api/admins/reshuffle`, { staleLeads: reshuffle })
      .then((res) => {
        setReshuffledLeads(res.data);
        let obj = {};
        res.data.forEach((e) => {
          if (obj[e['ic_id']]) {
            obj[e['ic_id']].push(e['lead_id']);
          } else {
            obj[e['ic_id']] = [e['lead_id']];
          }
        });
        setobj(obj);
      })
      .catch((err) => {
        setobj({});
        setReshuffledLeads([]);
        console.log('error', err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't post reShuffle"
        );
      });
  }

  function conformReShuffleLeads() {
    axios
      .post(`${base_url()}/api/admins/reshuffle-conform`, {
        updatedLeadsWithAssignee: reshuffledLeads,
      })
      .then((res) => {})
      .then(() => {
        fetchDisabledAccounts();
        reShuffleLeads();
      })
      .catch((err) => {
        console.log('error', err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't post reShuffle"
        );
      });
  }

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Button
        onClick={() => {
          reShuffleLeads();
          setOpenReshuffleModal(true);
        }}
        variant='contained'
        disabled={loading}
        sx={{ mb: 5 }}
      >
        Reshuffle
      </Button>
      <DataGrid
        rows={disabledAccounts}
        columns={columns}
        disableRowSelectionOnClick
        autoHeight
        getRowId={(row) => row.rowNumber}
      />

      <ReshuffleModal
        {...{
          obj,
          openReshuffleModal,
          setOpenReshuffleModal,
          reshuffledLeads,
          conformReShuffleLeads,
        }}
      />
    </>
  );
};

export default DisableAccountPage;
