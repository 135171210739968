import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { useAllAdmins } from '../AdminsContext';
import {
  CopyIcon,
  getFormattedTimestamp,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
const LeadDetails = ({ currentLead }) => {
  const { allAdmins } = useAllAdmins();
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) || {};
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));

  let emailCopyBtn = CopyIcon(currentLead?.email);
  const getEmailText = () => {
    if (!currentLead?.email) return detailsNotAvailable();
    else if (currentLead?.email === 'Hidden') return detailsHidden();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflowWrap: 'anywhere',
        }}
      >
        {currentLead?.email}
        {emailCopyBtn}
      </div>
    );
  };

  let MobileCopyBtn = CopyIcon(currentLead?.mobile);
  const getMobileText = () => {
    if (!currentLead?.mobile) return detailsNotAvailable();
    if (currentLead?.mobile === 'Hidden') return detailsHidden();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            backgroundColor: '#fde68d',
            padding: '2px',
            fontWeight: 'bold',
          }}
        >
          {currentLead?.mobile}
        </span>
        {MobileCopyBtn}
      </div>
    );
  };

  let whatsappCopyBtn = CopyIcon(currentLead?.whatsapp);
  const getWhatsappText = () => {
    if (!currentLead?.whatsapp) return detailsNotAvailable();
    if (currentLead?.whatsapp === 'Hidden') return detailsHidden();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            backgroundColor: '#fde68d',
            padding: '2px',
            fontWeight: 'bold',
          }}
        >
          {currentLead?.whatsapp}
        </span>
        {whatsappCopyBtn}
      </div>
    );
  };
  const getAssigneeText = () => {
    if (!currentLead?.assignee) {
      return <Typography>No assignee</Typography>;
    }

    const { fname, lname } = adminMap[currentLead?.assignee];

    return (
      <>
        <Typography>{`${fname} ${lname}`}</Typography>
      </>
    );
  };

  const snoozeDetails = function () {
    if (!currentLead?.snoozed_until_1) return detailsNotAvailable();

    return (
      <Stack
        direction={'row'}
        spacing={2}
        alignItems='center'
      >
        <Typography
          p={1}
          sx={{
            backgroundColor: '#2979ff',
            width: 'fit-content',
            borderRadius: '10px',
            color: '#fff',
            lineHeight: '1rem',
          }}
          display='flex'
          alignItems='center'
          gap='7px'
          fontSize='0.8rem'
        >
          {getFormattedTimestamp(new Date(currentLead?.snoozed_until_1))}
        </Typography>
        {currentLead?.has_scheduled_follow_up && <NotificationsActiveIcon />}
      </Stack>
    );
  };
  const detailsNotAvailable = () => {
    return (
      <span
        style={{
          color: '#cecece',
        }}
      >
        Unavailable
      </span>
    );
  };

  const detailsHidden = () => {
    return (
      <span
        style={{
          color: '#ffcccb',
        }}
      >
        Hidden
      </span>
    );
  };
  const getLinkedinLink = () => {
    if ((currentLead?.linkedin_link ?? '') === '') {
      return detailsNotAvailable();
    } else if (currentLead?.linkedin_link === 'Hidden') {
      return detailsHidden();
    }

    const link = `https://www.linkedin.com/in/${currentLead?.linkedin_link}`;
    return (
      <a
        style={{ marginLeft: 5 }}
        href={link}
        target='_blank'
      >
        {currentLead?.linkedin_link}
      </a>
    );
  };

  function getEmploymentText() {
    if (
      !currentLead?.experience &&
      !currentLead?.role &&
      !currentLead?.company
    ) {
      return detailsNotAvailable();
    }

    // There might be cases where the experience is not set to working_professional
    // but the role and company fields are present.
    let text = '';

    if (currentLead?.experience !== 'working_professional') {
      text += currentLead?.experience;
    }

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  const getExperienceText = () => {
    if (!currentLead?.experience) return detailsNotAvailable();
    return <div>{currentLead?.experience}</div>;
  };

  const getTechStackText = () => {
    if (!currentLead?.tech_stack) return detailsNotAvailable();
    return <div>{currentLead?.tech_stack}</div>;
  };

  const getCompanyText = () => {
    if (!currentLead?.company) return detailsNotAvailable();
    return <div>{currentLead?.company}</div>;
  };
  const getRoleText = () => {
    if (!currentLead?.role) return detailsNotAvailable();
    return <div>{currentLead?.role}</div>;
  };

  const getBranchText = () => {
    if (!currentLead?.branch) return detailsNotAvailable();
    return <div>{currentLead?.branch}</div>;
  };

  const getGradutionYearText = () => {
    if (!currentLead?.graduation_year) return detailsNotAvailable();
    return <div>{currentLead?.graduation_year}</div>;
  };

  function getCollegeText() {
    if (
      !currentLead?.branch &&
      !currentLead?.graduation_year &&
      !currentLead?.college
    ) {
      return detailsNotAvailable();
    }

    let text = '';
    if (currentLead?.college) {
      text += ' graduate from ' + currentLead?.college;
    }

    // required when branch is not present
    text = text.trim();

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  const getLabelInfo = () => {
    if (!currentLead?.label) return detailsNotAvailable();
    return (
      <Chip
        label={currentLead.label}
        sx={{
          backgroundColor: currentLead.label_color,
          color: currentLead.label_font_color,
        }}
      />
    );
  };

  const getCreatedAtDate = () => {
    if (!currentLead?.created_at) return detailsNotAvailable();
    return (
      <div>
        {getFormattedTimestamp(new Date(Date.parse(currentLead?.created_at)))}
      </div>
    );
  };

  const getProductText = () => {
    return (
      <>
        {currentLead?.product && <>Product&nbsp;</>}
        <span>
          {!currentLead?.product || currentLead?.product === ''
            ? detailsNotAvailable()
            : splitByUnderscoresAndCapitalize(currentLead?.product)}
        </span>
      </>
    );
  };

  const AssigneeDetails = [
    {
      label: 'Assigned to',
      value: getAssigneeText(),
    },
    {
      label: 'Next Follow up',
      value: snoozeDetails(),
    },
  ];
  const contactDetails = [
    {
      label: 'Email',
      value: getEmailText(),
    },
    {
      label: 'Phone',
      value: getMobileText(),
    },
    {
      label: 'Whatsapp',
      value: getWhatsappText(),
    },
    {
      label: 'Linkedin',
      value: getLinkedinLink(),
    },
  ];

  const professionalDetails = [
    {
      label: 'Role',
      value: getRoleText(),
    },
    {
      label: 'Company',
      value: getCompanyText(),
    },
    {
      label: 'Experience',
      value: getExperienceText(),
    },
    {
      label: 'Tech stack',
      value: getTechStackText(),
    },
    {
      label: 'Employment',
      value: getEmploymentText(),
    },
  ];

  const educationalDetails = [
    {
      label: 'Branch',
      value: getBranchText(),
    },
    {
      label: 'Graduation year',
      value: getGradutionYearText(),
    },
    {
      label: 'College',
      value: getCollegeText(),
    },
  ];

  const otherDetails = [
    {
      label: 'Created at',
      value: getCreatedAtDate(),
    },
    {
      label: 'Lead source type',
      value: getBranchText(),
    },
    {
      label: 'Product',
      value: getProductText(),
    },
    {
      label: 'Label',
      value: getLabelInfo(),
    },
  ];

  function getRow(label, value) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '6px',
        }}
      >
        <Box sx={{ width: '40%' }}>
          <Typography sx={{ fontSize: '0.8rem' }}>{label}</Typography>
        </Box>
        <Box sx={{ width: '60%' }}>
          <Typography sx={{ fontSize: '0.8rem' }}>{value}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <Box>
        <Box>
          <Typography
            sx={{ padding: '10px', fontWeight: 'bold', fontSize: '15px' }}
          >
            Lead Details
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ marginLeft: '10px', padding: '10px' }}>
          {AssigneeDetails.map((row) => getRow(row.label, row.value))}
        </Box>
      </Box>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <LocalPhoneOutlinedIcon fontSize='small' />
            <Typography fontSize='small'>Contact Details</Typography>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {contactDetails.map((row) => getRow(row.label, row.value))}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel2-content'
          id='panel2-header'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <WorkOutlineIcon fontSize='small' />
            <Typography fontSize='small'> Professional Details</Typography>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {professionalDetails.map((row) => getRow(row.label, row.value))}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel3-content'
          id='panel3-header'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <SchoolOutlinedIcon fontSize='small' />
            <Typography fontSize='small'> Education Details</Typography>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {educationalDetails.map((row) => getRow(row.label, row.value))}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls='panel4-content'
          id='panel4-header'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <FolderOpenOutlinedIcon fontSize='small' />
            <Typography fontSize='small'> Other Details</Typography>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {otherDetails.map((row) => getRow(row.label, row.value))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LeadDetails;
