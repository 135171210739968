import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Checkbox,
  Link,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from '../Admins/AdminDashboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import {
  currentAdminIsAdmin,
  filterByDate,
  getCurrentAdminId,
} from '../../utils/common';
import ManagerPoolSlider from './ManagerPoolSlider';
import { useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';
import GroupedSelect from './ManagerPoolGroupSelect';
import CustomLoader from '../customLoader';

function ManagerPoolAction({ managers, admin, selectedManager, ics }) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [showAdditionalBox, setShowAdditionalBox] = useState(false);
  const [freshleads, setFreshleads] = useState([]);
  const [tapLeads, setTapleads] = useState([]);
  const [leadAccessPolicies, setLeadAccessPolicies] = useState([]);
  const [managerPoolLogs, setManagerPoolLogs] = useState([]);
  const [assignedLeads, setAssignedLeads] = useState([]);
  const [freshLeads2, setFreshLeads2] = useState([]);
  const [SelectedValue, setSelectedValue] = useState(null);
  const [lead, setLead] = useState();
  const [label, setLabel] = useState('');
  const [leadExperience, setLeadExperience] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [labelFilter, setLabelFilter] = useState([]);
  const [utmSource, setUtmSource] = useState([]);
  const [labelFiltersList, setLabelFiltersList] = useState([]);
  const [leads, setLeadState] = useState(0);
  const [isLoading, setIsloading] = useState(true);
  const [selectedManagerName, setSelectedManagerName] = useState();
  const [bucketSize, setBucketSize] = useState([]);
  const navigate = useNavigate();

  let leadExperienceFilters = [
    'student',
    'graduated',
    'working_professional',
    'working_professional_0_2_exp',
    'working_professional_2_5_exp',
    'working_professional_5_plus_exp',
  ];

  let utmSourceFilters = [
    'instagram',
    'whatsapp',
    'linkedin',
    'emails',
    'youtube',
    'telegram',
    'facebook',
    'meta',
    'x',
  ];

  let leadStatusFilters = ['Fresh', 'Tapped'];
  const handleLeadChange = (event) => {
    let value = parseInt(event.target.value);
    value = Math.max(0, Math.min(value, freshLeads2.length));
    if (isNaN(value)) {
      setLead(0);
      setLeadState(0);
    } else {
      setLeadState(value);
      setLead(value);
    }
  };

  const isLoggedinManagerOrAdmin =
    admin.roles.includes('admin') || admin.roles.includes('senior_manager');
  const managerswithoutAnup = isLoggedinManagerOrAdmin
    ? managers.filter((e) => e.id !== 206).map((e) => e.id)
    : [admin.id];
  const managersDetailsWithoutAnup = managers
    .filter((e) => e.id !== 206)
    .map((e) => {
      return { id: e.id, name: e.fname + ' ' + e.lname };
    });

  const icsList = ics.map((e) => {
    return { id: e.id, name: e.fname + ' ' + e.lname };
  });

  const icsIds =
    leadAccessPolicies[0]?.length > 0 &&
    leadAccessPolicies[0].map((e) => e.lead_owner_id);

  useEffect(() => {
    if (isLoggedinManagerOrAdmin || admin.roles.includes('lead')) {
      getPooledLeads();
      getFreshLeads();
      getTapLeads();
      getLeadAccessPoliciesOfManager();
    } else {
      getLeadAccessPoliciesOfManager();
    }
    getManagerPoolLogs();
  }, [selectedManager, leadExperience, leadStatus, labelFilter, utmSource]);

  useEffect(() => {
    if (!isLoggedinManagerOrAdmin && leadAccessPolicies.length > 0) {
      getAssignedLeads();
    } else if (isLoggedinManagerOrAdmin) {
      getBucketSize();
    }
  }, [leadAccessPolicies]);

  const handlePastActionsClick = () => {
    setShowAdditionalBox(!showAdditionalBox);
  };

  const handleTabChange = (e, newTabValue) => {
    setCurrentTab(newTabValue);
  };

  const mapfunctionForGroups = () => {
    let managers = managersDetailsWithoutAnup.map((e) => {
      return {
        name: e.name,
        id: e.id,
        options: [
          ' fresh Lead in pool',
          ' tapped lead in pool',
          ' reporting ics',
        ],
      };
    });

    for (let i = 0; i < freshleads?.length; i++) {
      const managerId = managers[i].id;
      const tapLeadsCount =
        tapLeads?.find((tl) => tl.Manager_id === managerId)?.lead_ids.length ||
        0;
      const freshLeadCount = freshleads[i]?.length - tapLeadsCount;
      managers[i] = {
        name: managers[i]['name'],
        id: managers[i]['id'],
        options: [
          freshLeadCount + managers[i]['options'][0],
          tapLeadsCount + managers[i]['options'][1],
          leadAccessPolicies[i]?.length + managers[i]['options'][2],
        ],
      };
    }

    return managers;
  };

  const allICsGroup = () => {
    if (icsList.length === 0) {
      return [];
    }

    // Filter leads by owner
    const filterLeadsByOwner = (ownerId) => {
      return bucketSize.filter((lead) => lead.owner === ownerId);
    };

    // Calculate leads based on date criteria
    const calculateLeadStatistics = (leads) => {
      const now = new Date();
      const startOfToday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      const startOfYesterday = new Date(
        startOfToday.getTime() - 24 * 60 * 60 * 1000
      );
      const startOfWeek = new Date(startOfToday);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const startOfLastWeek = new Date(
        startOfWeek.getTime() - 7 * 24 * 60 * 60 * 1000
      );

      const leadsInBucketRightNow = leads.filter(
        (lead) => lead.closed_at === null
      );
      const leadsAssignedToday = leads.filter(
        (lead) => new Date(lead.created_at) >= startOfToday
      );
      const leadsAssignedYesterday = leads.filter(
        (lead) =>
          new Date(lead.created_at) >= startOfYesterday &&
          new Date(lead.created_at) < startOfToday
      );
      const leadsAssignedThisWeek = leads.filter(
        (lead) => new Date(lead.created_at) >= startOfWeek
      );
      const leadsAssignedLastWeek = leads.filter(
        (lead) =>
          new Date(lead.created_at) >= startOfLastWeek &&
          new Date(lead.created_at) < startOfWeek
      );

      return {
        leadsInBucketRightNow: leadsInBucketRightNow.length,
        leadsAssignedToday: leadsAssignedToday.length,
        leadsAssignedYesterday: leadsAssignedYesterday.length,
        leadsAssignedThisWeek: leadsAssignedThisWeek.length,
        leadsAssignedLastWeek: leadsAssignedLastWeek.length,
      };
    };

    // Map through ICs and update with lead statistics
    let icDetails = icsList.map((ic) => {
      const leads = filterLeadsByOwner(ic.id);
      const stats = calculateLeadStatistics(leads);

      return {
        name: ic.name,
        id: ic.id,
        options: [
          `${stats.leadsInBucketRightNow} leads in bucket right now`,
          `${stats.leadsAssignedToday} leads assigned today`,
          `${stats.leadsAssignedYesterday} leads assigned yesterday`,
          `${stats.leadsAssignedThisWeek} leads assigned this week`,
          `${stats.leadsAssignedLastWeek} leads assigned last week`,
        ],
      };
    });

    return icDetails;
  };

  const mapfunctionForICsGroups = () => {
    if (leadAccessPolicies.length === 0) {
      return [];
    }

    let icDetails = leadAccessPolicies[0].map((e) => {
      return {
        name: (e?.fname ? e.fname : '') + ' ' + (e?.lname ? e.lname : ''),
        id: e?.id,
        options: [
          ' leads in bucket right now',
          ' leads assigned today',
          ' leads assigned yesterday',
          ' leads assigned this week',
          ' leads assigned last week',
        ],
      };
    });

    for (let i = 0; i < assignedLeads?.length; i++) {
      let filteredLeads = [];
      const totalLeads = assignedLeads[i]?.length || 0;

      if (totalLeads > 0) {
        filteredLeads = filterByDate(assignedLeads[i]) || [];
      }

      if (icDetails[i]) {
        icDetails[i] = {
          name: icDetails[i]?.name || '',
          id: icDetails[i]?.id || null,
          options: [
            `${totalLeads}${
              icDetails[i].options[0] || ' leads in bucket right now'
            }`,
            `${totalLeads > 0 ? filteredLeads[0]?.length || 0 : 0}${
              icDetails[i].options[1] || ' leads assigned today'
            }`,
            `${totalLeads > 0 ? filteredLeads[1]?.length || 0 : 0}${
              icDetails[i].options[2] || ' leads assigned yesterday'
            }`,
            `${totalLeads > 0 ? filteredLeads[2]?.length || 0 : 0}${
              icDetails[i].options[3] || ' leads assigned this week'
            }`,
            `${totalLeads > 0 ? filteredLeads[3]?.length || 0 : 0}${
              icDetails[i].options[4] || ' leads assigned last week'
            }`,
          ],
        };
      }
    }

    return icDetails;
  };

  const getFreshLeads = async () => {
    setIsloading(true);
    let url =
      base_url() +
      `/api/manager/manager-pools?id=${selectedManager}&experience=${leadExperience}&status=${leadStatus}&label=${labelFilter}&utm_source=${utmSource}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setFreshLeads2(res?.data);
          setLabelFiltersList((prevFilters) => [...prevFilters, ...res.data]);
          setIsloading(false);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching fresh leads.'
        );
      });
  };

  const getPooledLeads = async () => {
    setIsloading(true);
    let url =
      base_url() +
      `/api/manager/manager-pools-inbulk?ids=${managerswithoutAnup}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setFreshleads(res?.data);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching fresh leads.'
        );
      });
  };

  const getTapLeads = async () => {
    setIsloading(true);
    let url =
      base_url() + `/api/manager_pool/tapped_leads?ids=${managerswithoutAnup}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setIsloading(false);
          setTapleads(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const getLeadAccessPoliciesOfManager = async () => {
    setIsloading(true);
    let url = '';
    if (currentAdminIsAdmin() || getCurrentAdminId() === 206) {
      url =
        base_url() +
        `/api/lead-access-policies-for-manager?managerIds=${managerswithoutAnup}`;
    } else {
      url =
        base_url() +
        `/api/lead-access-policies-for-managers?managerIds=${managerswithoutAnup}`;
    }

    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setLeadAccessPolicies(res?.data);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching leads access policies.'
        );
      });
  };
  const getManagerNameById = (id) => {
    const manager = managers.find((manager) => manager.id === id);
    return manager ? `${manager.fname} ${manager.lname}` : 'You';
  };
  const getManagerPoolLogs = async () => {
    setIsloading(true);
    const name = getManagerNameById(selectedManager);
    setSelectedManagerName(name);
    let url = '';
    if (currentAdminIsAdmin() || getCurrentAdminId() === 206) {
      url =
        base_url() +
        `/api/manager/manager-pool-logs?id=${admin.id}&selectedManager=${selectedManager}`;
    } else {
      url = base_url() + `/api/manager/manager-pool-logs?id=${admin.id}`;
    }

    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setManagerPoolLogs(res?.data);
          setIsloading(false);
        }
      })
      .catch((err) => {
        setIsloading(false);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching manager pool logs.'
        );
      });
  };

  const getAssignedLeads = async () => {
    setIsloading(true);
    const url =
      base_url() + `/api/manager/bulk-leads-assignments?ids=${icsIds}`;

    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setAssignedLeads(res?.data);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching leads assignments.'
        );
      });
  };

  const getBucketSize = async () => {
    setIsloading(true);
    const url = base_url() + `/api/manager_pool/bucket_size`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          setBucketSize(res?.data);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching leads assignments.'
        );
      });
  };

  const formatDateToString = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const handleAssignment = async () => {
    try {
      const leadIds = freshLeads2.slice(0, lead).map((obj) => obj.lead_id);
      const res = await axios.post(`${base_url()}/api/manager/manager-pools`, {
        leadIds,
        pooled_by: admin.id,
        pooled_to: SelectedValue.id,
        label: label ? label : '',
      });
      if (res.status === 200) {
        setIsSuccess(true);
        setSuccessMessage(
          `Assigned ${leadIds.length} fresh leads to ${SelectedValue.name}`
        );
        setSelectedValue(null);
        setLabel('');
        setLeadState(0);
        setLead(0);
        getFreshLeads();
        getPooledLeads();
        getTapLeads();
        getManagerPoolLogs();
      } else {
        setHasError(true);
        setErrorMessage(
          `Failed to assign fresh leads to ${SelectedValue.name}`
        );
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(
        error['response']['data']['error'] || 'Failed to Assign Fresh Leads'
      );
    }
  };

  const handleLabelFilterChange = (e) => {
    setLabelFilter(e.target.value, () => {
      getFreshLeads();
    });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 3,
            marginBottom: 2,
            width: '90%',
          }}
        >
          {/* Filter 1 */}
          <Box style={{ width: '24%' }}>
            <InputLabel
              id='label1'
              sx={{
                marginBottom: 0.5,
                color: 'grey',
              }}
            >
              Tag
            </InputLabel>
            <input
              type='text'
              onChange={handleLabelFilterChange}
              placeholder='Enter a Tag'
              style={{
                width: '100%',
                padding: '0.7rem',
                borderRadius: '0.25rem',
                border: '1px solid #ccc',
                outline: 'none',
              }}
            />
          </Box>

          <Box style={{ width: '24%' }}>
            <InputLabel
              id='label2'
              sx={{ marginBottom: 0.5, color: 'grey' }}
            >
              Experience
            </InputLabel>
            <Select
              labelId='Label2-label'
              multiple
              value={leadExperience}
              onChange={(e) => setLeadExperience(e.target.value)}
              size='small'
              sx={{ width: '100%' }}
              renderValue={(selected) => selected.join(', ')}
            >
              {leadExperienceFilters.map((filter, index) => (
                <MenuItem
                  key={index}
                  value={filter}
                >
                  <Checkbox checked={leadExperience.indexOf(filter) > -1} />
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box style={{ width: '24%' }}>
            <InputLabel
              id='label3'
              sx={{ marginBottom: 0.5, color: 'grey' }}
            >
              Lead Status
            </InputLabel>
            <Select
              labelId='Label3-label'
              multiple
              value={leadStatus}
              onChange={(e) => setLeadStatus(e.target.value)}
              size='small'
              sx={{ width: '100%' }}
              renderValue={(selected) => selected.join(', ')}
            >
              {leadStatusFilters.map((filter, index) => (
                <MenuItem
                  key={index}
                  value={filter}
                >
                  <Checkbox checked={leadStatus.indexOf(filter) > -1} />
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box style={{ width: '24%' }}>
            <InputLabel
              id='label4'
              sx={{ marginBottom: 0.5, color: 'grey' }}
            >
              UTM_Source
            </InputLabel>
            <Select
              labelId='Label4-label'
              multiple
              value={utmSource}
              onChange={(e) => setUtmSource(e.target.value)}
              size='small'
              sx={{ width: '100%' }}
              renderValue={(selected) => selected.join(', ')}
            >
              {utmSourceFilters.map((filter, index) => (
                <MenuItem
                  key={index}
                  value={filter}
                >
                  <Checkbox checked={utmSource.indexOf(filter) > -1} />
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Box
          sx={{
            width: '8%',
            position: 'relative',
          }}
        >
          <Typography
            style={{
              color: 'grey',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={handlePastActionsClick}
          >
            Past&nbsp;Actions
          </Typography>
          {showAdditionalBox && (
            <Box
              sx={{
                border: '1px solid grey',
                width: '310px',
                position: 'absolute',
                borderRadius: '10px',
                backgroundColor: 'white',
                top: '30%',
                right: '-70px',
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  maxHeight: '250px',
                  overflowY: 'auto',
                }}
              >
                <Box sx={{ padding: '10px' }}>
                  <Typography variant='h6'>
                    {managerPoolLogs.length > 0
                      ? 'Past Actions'
                      : 'No Past Actions'}
                  </Typography>
                  <Divider />
                </Box>
                {managerPoolLogs.length > 0 &&
                  managerPoolLogs.map((e, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        <Typography>
                          {e.pooled_by === admin.id
                            ? 'You'
                            : `${selectedManagerName}`}{' '}
                          {e.status === 'created' ? 'Pooled' : e.status}
                          &nbsp;
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '700',
                              textDecoration: 'underline',
                            }}
                          >
                            {e['manager_pool_leads'].length}
                            {e['manager_pool_leads'].length === 1
                              ? ' Lead'
                              : ' Leads'}
                          </span>
                          <br />
                          to {e['admin']['fname'] + ' ' + e['admin']['lname']}
                        </Typography>
                        {e.status === constants.POOL_LEAD_STATUS_ASSIGNED && (
                          <Box
                            sx={{
                              marginLeft: 'auto',
                              padding: '15px',
                              alignItems: 'start',
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                navigate('/bucket-search', {
                                  state: e.manager_pool_leads,
                                })
                              }
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: '5px',
                        }}
                      >
                        <Typography sx={{ color: 'grey', fontSize: '14px' }}>
                          {formatDateToString(e.created_at)}
                        </Typography>
                      </Box>
                      {index < managerPoolLogs.length - 1 && <Divider />}
                    </Box>
                  ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: '10px',
          width: '100%',
        }}
      >
        <h4 style={{ color: 'grey', fontWeight: 'normal', fontSize: '14px' }}>
          {freshLeads2[0]?.created_at
            ? new Date(freshLeads2[0]?.created_at).toLocaleString()
            : ''}
        </h4>
        <h4 style={{ color: 'grey', fontWeight: 'normal', fontSize: '14px' }}>
          {freshLeads2[freshLeads2.length - 1]?.created_at
            ? new Date(
                freshLeads2[freshLeads2.length - 1]?.created_at
              ).toLocaleString()
            : ''}
        </h4>
      </Box>
      {isLoading ? (
        <CustomLoader height={60} />
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
              marginRight='10px'
            >
              <Typography>{leads} Leads Selected</Typography>
            </Box>
            <Box
              sx={{
                width: '90%',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <ManagerPoolSlider
                setLead={setLead}
                lead={leads}
                setLeadState={setLeadState}
                freshLeads2={freshLeads2}
              />
            </Box>
            <Box
              sx={{
                width: 'fit-content',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ ml: '1.5rem', mr: '1rem' }}>
                {freshLeads2.length} Leads
              </Typography>
            </Box>
            <Box
              sx={{
                width: '150px',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                marginLeft: '10px',
              }}
            >
              <TextField
                type='number'
                label='Enter Leads'
                value={leads}
                onChange={handleLeadChange}
                disabled={freshLeads2.length === 0}
                inputProps={{ min: 0, max: freshLeads2.length }}
                sx={{ width: '100px' }}
              />
            </Box>
          </Box>

          {leads !== 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'hidden',
                flexWrap: 'wrap',
              }}
            >
              <Box
                width='380px'
                height='20rem'
                border='0.1px solid lightgrey'
                borderRadius='5px'
                padding='5px'
                overflow='hidden'
                margin='1rem'
                alignItems='center'
                textAlign='center'
              >
                <Box>
                  <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    sx={{ marginLeft: '30px' }}
                  >
                    {isLoggedinManagerOrAdmin && <Tab label='RePool' />}
                    <Tab label='Assign' />
                  </Tabs>
                </Box>
                <Box>
                  <Divider
                    orientation='horizontal'
                    variant='middle'
                    sx={{
                      height: '1px',
                      width: '75%',
                      marginLeft: '15px',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box>
                    {isLoggedinManagerOrAdmin && (
                      <TabPanel
                        value={currentTab}
                        index={0}
                      >
                        <Typography
                          sx={{ alignItems: 'center', mb: '5px' }}
                        ></Typography>

                        <Box sx={{ ml: '15px' }}>
                          <Box>
                            <TextField
                              onChange={(e) => setLabel(e.target.value)}
                              placeholder='Add a Tag'
                              sx={{ width: '17rem', paddingBottom: '1rem' }}
                              value={label}
                            />
                          </Box>
                          <GroupedSelect
                            setSelectedValue={setSelectedValue}
                            SelectedValue={SelectedValue}
                            label='Select Manager'
                            groups={mapfunctionForGroups()}
                          />
                          <br />

                          <br />
                          <Button
                            variant='contained'
                            onClick={handleAssignment}
                          >
                            Repool
                          </Button>
                        </Box>
                      </TabPanel>
                    )}

                    <TabPanel
                      value={currentTab}
                      index={isLoggedinManagerOrAdmin ? 1 : 0}
                    >
                      <Box sx={{ marginLeft: '15px' }}>
                        <Typography sx={{ color: 'grey' }}></Typography>
                        <Box
                          sx={{
                            width: '17rem',
                          }}
                        >
                          <GroupedSelect
                            setSelectedValue={setSelectedValue}
                            SelectedValue={SelectedValue}
                            label='Select IC'
                            groups={
                              getCurrentAdminId() === 206 ||
                              currentAdminIsAdmin()
                                ? allICsGroup()
                                : mapfunctionForICsGroups()
                            }
                          />
                          <br />
                          <br />
                          <Button
                            variant='contained'
                            onClick={handleAssignment}
                          >
                            Assign
                          </Button>
                        </Box>
                      </Box>
                    </TabPanel>

                    <TabPanel
                      value={currentTab}
                      index={isLoggedinManagerOrAdmin ? 2 : 1}
                    >
                      <Box sx={{ ml: '30px' }}>
                        <Box>
                          <TextField
                            onChange={(e) => setLabel(e.target.value)}
                            placeholder='add label'
                            sx={{ width: '17rem', paddingBottom: '1rem' }}
                          />
                        </Box>
                        <Box>
                          <GroupedSelect
                            setSelectedValue={setSelectedValue}
                            SelectedValue={SelectedValue}
                            label='Select Manager'
                            groups={
                              isLoggedinManagerOrAdmin
                                ? mapfunctionForGroups()
                                : mapfunctionForICsGroups()
                            }
                          />
                          <br />
                          <br />
                          <Button
                            variant='contained'
                            onClick={handleAssignment}
                          >
                            Pool
                          </Button>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default ManagerPoolAction;
