import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Logo = (props) => {
  const navigate = useNavigate();
  return (
    <img
      src='/logo.png'
      width='150px'
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('/bucket-search')}
    />
  );
};
