import React, { useEffect, useRef } from 'react';
import { Stack, Typography, Grid, Box } from '@mui/material';
import constants from '../../../utils/training/constants';

const Recording = ({ selectedModule, videoLink, sendClickDataForTrainee }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoLink && videoRef.current) {
      videoRef.current.src = videoLink;
    }
  }, [videoLink, selectedModule]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => {
      sendClickDataForTrainee(constants.VIDEO_PLAYED, selectedModule);
    };

    const handlePause = () => {
      sendClickDataForTrainee(constants.VIDEO_PAUSED, selectedModule);
    };

    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);

      return () => {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
      };
    }
  }, [selectedModule, sendClickDataForTrainee]);

  return (
    <Box>
      <Grid
        item
        xs={10}
      >
        <Stack>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
            }}
          >
            {videoLink ? (
              <video
                ref={videoRef}
                width='100%'
                height='450'
                controls
              >
                <source
                  src={videoLink}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <Typography>Video is not avaliable</Typography>
            )}
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
};

export default Recording;
