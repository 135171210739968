import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LeadDropRequestDetails from '../../components/users/LeadDropRequestDetails';
import { useCurrentLead } from '../../components/CurrentLeadContext';
import { InteractionsList } from '../../components/users/InteractionsList';
import { useAllAdmins } from '../../components/AdminsContext';
import axios from 'axios';

import { base_url } from '../../components/Mode';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import LeadDetails from '../../components/leadDetailsComponents/LeadDetails';
import CustomLoader from '../customLoader';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import { currentAdminIsAdmin, currentAdminIsManager } from '../../utils/common';
import {
  fetchCallLogs,
  getCPEDetails,
} from '../../components/SpeechRecognition/utils';

const NewDropRequestUi = ({ lead_id }) => {
  const [raisedDropRequestDetails, setRaisedDropRequestDetails] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState({
    primary: '',
    secondary: {},
  });
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [allDropReasons, setAllDropReasons] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successText, setSuccessText] = React.useState('');
  const [showActivity, setShowActivity] = useState(true);
  const [callLog, setCallLog] = useState(null);
  const {
    loading,
    getCurrentLeadAndUpdateContext,
    currentLead,
    setCurrentLead,
    getCurrentLeadData,
    getCurrentLeadInteractionsAndUpdateContext,
  } = useCurrentLead();
  const { allAdmins } = useAllAdmins();
  const currentAdmin = JSON.parse(localStorage.getItem('admin')) || {};
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));

  useEffect(() => {
    getCurrentLeadData(lead_id);
  }, [lead_id]);

  useEffect(() => {
    const fetchDropReasons = () => {
      axios
        .get(
          `${base_url()}/api/users/drop_reasons?status=${currentLead.status}`
        )
        .then((res) => {
          if (res.data) {
            setAllDropReasons(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorText(
            err.response?.data?.message || "Couldn't fetch the drop reasons."
          );
        });
    };

    if (currentLead?.status) {
      fetchDropReasons();
    }

    return () => {};
  }, [currentLead?.status]);

  useEffect(() => {
    leadDropRequest(currentLead?.id);
  }, [currentLead?.id]);

  const leadDropRequest = async (id) => {
    if (!id) {
      return;
    }
    try {
      const res = await axios.get(
        `${base_url()}/api/manager_actions/${id}/drop_request`
      );
      if (res.data !== null) {
        setRaisedDropRequestDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorText(
        error.response?.data?.message || "Couldn't fetch the drop request."
      );
    }
  };

  const handleShowActivity = () => {
    setShowActivity(!showActivity);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorText}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successText}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            width: '35%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          {!loading.Lead ? (
            <LeadDetails currentLead={currentLead} />
          ) : (
            <CustomLoader height={180} />
          )}
        </Box>
        <Box
          sx={{
            width: '65%',
            padding: '5px',
          }}
        >
          <Box sx={{ marginLeft: '25px' }}>
            {raisedDropRequestDetails.length > 0 &&
            Object.keys(allDropReasons).length > 0 ? (
              <LeadDropRequestDetails
                {...{
                  reasons: allDropReasons,
                  selectedReasons,
                  setSelectedReasons,
                  leadDropRequest,
                  raisedDropRequestDetails,
                }}
              />
            ) : (
              <CustomLoader height={80} />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              padding: '0 20px 0 0',
            }}
          >
            {!showActivity &&
              (currentAdminIsAdmin() || currentAdminIsManager()) && (
                <Button
                  onClick={() =>
                    fetchCallLogs(
                      currentLead,
                      setIsLoading,
                      setHasError,
                      setErrorText,
                      setIsSuccess,
                      setSuccessText,
                      setCallLog
                    )
                  }
                  disabled={isLoading}
                >
                  {isLoading ? 'Fetching' : 'Load Call Logs'}
                </Button>
              )}
            {!showActivity && (
              <Button
                onClick={() =>
                  getCPEDetails(
                    currentLead,
                    setIsLoading,
                    setHasError,
                    setErrorText,
                    setIsSuccess,
                    setSuccessText,
                    setCurrentLead
                  )
                }
                disabled={isLoading}
              >
                {isLoading ? 'Fetching' : 'Load CPE Details'}
              </Button>
            )}
            <InputLabel
              htmlFor='showActivityCheckbox'
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Checkbox
                id='showActivityCheckbox'
                sx={{ marginY: 1, padding: 0 }}
                {...label}
                onChange={handleShowActivity}
                checked={showActivity}
              />
              <Typography
                sx={{
                  marginLeft: '2px',
                  fontSize: '15px',
                }}
              >
                Show external activities only
              </Typography>
            </InputLabel>
          </Box>

          <Box sx={{ width: '95%', margin: 'auto' }}>
            <InteractionsList
              callLog={callLog}
              adminMap={adminMap}
              showActivity={showActivity}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewDropRequestUi;
