import { Divider, Typography, Box, Modal } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url } from '../components/Mode';
import { Search } from '../components/search/Search';
import LeadsDataTable from '../components/data-table/LeadsDataTable';
import {
  getOffsetFromPage,
  currentAdminIsIC,
  currentAdminIsTrainee,
  getCurrentAdminId,
} from '../utils/common';

import { buildQueryStringFiltersToFetchLeads } from '../components/users/GetLeads';
import { Dialog, DialogContent, DialogContentText, Stack } from '@mui/material';
import LeadRequestModal from '../components/ToastNotifications/LeadRequestModal';
import Spinner from '../components/ToastNotifications/LeadRequestModal/Spinner';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesomeOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import constants from '../utils/constants';
import { useSocket } from '../socket/socketContext';

const initialState = {
  id: '',
  name: '',
  email: '',
  snoozed: '',
  snoozed_until: '',
  assignee: '',
  experience: '',
  college: '',
  company: '',
  role: '',
  label: '',
  lead_source_type: '',
  utm_campaign: '',
  status: '',
  product: '',
  note: '',
  tech_stack: '',
  lead_created_from: '',
  lead_created_to: '',
  tech_stack_type: '',
  pooled_state: '',
};

export function GlobalSearch() {
  // 'queryParams' contains all the query params from the browser's search bar as well as all the filters
  // manually added through global search options.
  const [queryParams, setQueryParams] = useState({});
  const [leadRequestStatusMessage, setLeadRequestStatusMessage] =
    useState(undefined);
  const [leadRequestSuccessful, setLeadRequestSuccessful] = useState(false);
  const [requestLeadButtonClicked, setRequestLeadButtonClicked] =
    useState(false);
  const [hotLeadsCheck, setHotLeadsCheck] = useState(false);
  const [leads, setLeads] = useState([]);
  const [limit, setLimit] = useState(50);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState('');
  const [searchParams, setSearchParams] = useState({}); // this only contains all the query params from the browser's search bar
  const [globalFilters, setGlobalFilters] = useState(initialState);
  const loc = useLocation();
  const navigate = useNavigate();
  const [openLeadRequestDescModal, setOpenLeadRequestDescModal] =
    useState(false);
  const [isOpenLeadRequestModal, setIsOpenLeadRequestModal] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [requestedLeadData, setRequestedLeadData] = useState('');
  const [isDisableRequestLeadButton, setIsDisableRequestLeadButton] =
    useState(false);
  const [isRequestNewLeadHovered, setIsRequestNewLeadHovered] = useState(false);
  const [fetachingLeadData, setFetachingLeadData] = useState(false);

  const [isClassSet, setIsClassSet] = useState(false);
  const location = useLocation();
  const [socket] = useSocket();
  useEffect(() => {
    if (location.state) {
      setGlobalFilters({ ...globalFilters, id: location.state });
    }
  }, [location.state]);

  useEffect(() => {
    if (socket && currentAdminIsIC()) {
      socket.emit('userOnline', { userId: getCurrentAdminId() });
    }
  }, []);

  useEffect(() => {
    canLeadRequested();
    // URL params are being converted to filters for the API calls made through axios.
    if (loc.search.length > 1) {
      // because loc.search[0] = "?"
      const tmpQueryParams = {};
      const items = loc.search.substring(1).split('&');
      items.forEach((item, index) => {
        let [key, val] = item.split('=');
        val = val.replace(/%20/g, ' '); // browser converts " " => "%20" so we need to convert all of them back to " ".
        tmpQueryParams[key] = val;
      });
      setSearchParams(tmpQueryParams);
    }
    return () => {};
  }, [loc]);

  // Fetches data on page load
  useEffect(() => {
    if (
      (loc.search.length > 1 && Object.keys(searchParams).length > 0) ||
      loc.search.length <= 1
    ) {
      fetchData();
    }
  }, [currentPage, limit, columnFilters, searchParams]);

  const handleMouseEnterForLeadRequest = () => {
    setIsRequestNewLeadHovered(true);
  };

  const handleMouseLeaveForLeadRequest = () => {
    setIsRequestNewLeadHovered(false);
  };

  const openLeadRequestModal = () => {
    setIsOpenLeadRequestModal(true);
  };

  const closeLeadRequestModal = () => {
    setIsOpenLeadRequestModal(false);
  };

  const getSearchUrl = () => {
    const presetFilters = { ...searchParams }; // this also only contains all the query params from the browser's search bar
    const offset = getOffsetFromPage(currentPage, limit);
    let params = getAllGlobalFilters();
    setQueryParams({ ...params, ...presetFilters });

    let queryString = buildQueryStringFiltersToFetchLeads({
      ...params,
      ...presetFilters,
    });
    queryString += `&limit=${limit}`;
    queryString += `&offset=${offset}`;

    let url = base_url() + '/api/users';
    if (columnFilters !== '') {
      queryString += '&columnFilters[]=' + columnFilters;
    }
    url += '?' + queryString;
    return url;
  };

  const getLeadRequestUrl = () => {
    return base_url() + '/api/assignments';
  };

  const getLeadRequestValidationUrl = () => {
    return base_url() + '/api/salsa/lead-request-validation';
  };

  const filterLeads = (leads, pooledState) => {
    if (pooledState === 'pooled') {
      return leads.filter((lead) => lead.pooled_status === 'pooled');
    } else if (pooledState === 'unpooled') {
      return leads.filter((lead) => lead.pooled_status === 'unpooled');
    } else {
      return leads;
    }
  };

  const fetchData = () => {
    let url = getSearchUrl();
    setFetachingLeadData(true);
    axios.get(url).then((res) => {
      if (res['data']) {
        const filteredLeads = filterLeads(
          res.data.leads,
          globalFilters.pooled_state
        );
        setLeads(filteredLeads);
        setTotalLeads(res['data']['total']);
      }
      setFetachingLeadData(false);
    });
  };

  const canLeadRequested = () => {
    let leadRequestValidationUrl = getLeadRequestValidationUrl();
    if (leadRequestValidationUrl) {
      axios
        .get(leadRequestValidationUrl)
        .then((res) => {
          setIsClassSet(true);
        })
        .catch((err) => {
          if (err.response.data.message != 'Class not set') {
            setIsClassSet(true);
          }
          setIsDisableRequestLeadButton(true);
          setLeadRequestStatusMessage(
            err.response ? err.response.data.message : err
          );
        });
    }
  };

  async function requestLead() {
    setIsSpinner(true);
    let url = getLeadRequestUrl();
    // await is required so that we only proceed further after the button is disabled. This helps us avoid repeat assignments.
    await setRequestLeadButtonClicked(true);
    setLeadRequestStatusMessage(undefined);
    setLeadRequestSuccessful(false);
    axios
      .post(url)
      .then((res) => {
        setRequestedLeadData(res.data);
        setIsSpinner(false);
        openLeadRequestModal();
      })
      .catch((err) => {
        setIsSpinner(false);
        setIsDisableRequestLeadButton(true);
        setLeadRequestStatusMessage(
          err.response ? err.response.data.message : err
        );
      })
      .finally(() => {
        setRequestLeadButtonClicked(false);
        setIsSpinner(false);
      });
  }

  const getAllGlobalFilters = () => {
    let params = {};
    for (let filter in globalFilters) {
      if (globalFilters[filter] === '' || !globalFilters[filter]) {
        continue;
      }
      if (filter === 'assignee' && globalFilters[filter] === '') {
        continue;
      }
      if (filter === 'snoozed_until') {
        params['snoozed_till'] = `${globalFilters[filter]}T23:59:59`; // defaults to EOD.
        continue;
      }
      params[filter] = globalFilters[filter];
    }

    if (hotLeadsCheck) {
      params['is_hot'] = 1;
    }
    return params;
  };

  const handleHotLeadsSelect = (event) => {
    if (event === 'reset') {
      setHotLeadsCheck(false);
    } else {
      setHotLeadsCheck(!hotLeadsCheck);
    }
  };

  const resetAllFilters = (e) => {
    // this resets globalFilters as well as filters set in browser's search bar.
    setGlobalFilters(initialState);
    handleHotLeadsSelect('reset');
    setSearchParams({});
    setQueryParams({});
    navigate('/bucket-search');
  };

  const RequestNewLeadTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'black',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #dadde9',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  }));

  const salsaDrivenLeadAssignment = () => {
    let text = [
      <span
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onMouseEnter={handleMouseEnterForLeadRequest}
        onMouseLeave={handleMouseLeaveForLeadRequest}
      >
        <RequestNewLeadTooltip
          open={isRequestNewLeadHovered && isDisableRequestLeadButton}
          title={leadRequestStatusMessage ? leadRequestStatusMessage : ''}
          placement='top'
        >
          <IconButton
            variant='contained'
            onClick={async () => {
              await requestLead();
            }}
            disabled={requestLeadButtonClicked || isDisableRequestLeadButton}
            display='inline-block'
            sx={{
              marginBottom: '10px',
              width: '210px',
              padding: '10px',
              height: '40px',
              borderRadius: '5px',
              background:
                'linear-gradient(90deg, rgba(111, 0, 255, 0.60) 0%, #6F00FF 100%)',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              borderColor: '#4285f4',
              borderWidth: 1,
              color: 'white',
              fontSize: '15px',
              fontWeight: 600,
              transition: 'background 0.3s ease',
              '&:hover': {
                background: 'linear-gradient(to right, #6f00ff, #9a4cff)',
                cursor: 'pointer',
              },
            }}
          >
            <AutoAwesomeIcon
              sx={{
                marginRight: '10px',
              }}
            />
            Request new lead
          </IconButton>
        </RequestNewLeadTooltip>
      </span>,
    ];

    text.push(
      <>
        <Typography
          variant='p'
          marginLeft={1}
          component='div'
          gutterBottom
          fontFamily={"'Roboto'"}
          fontWeight={400}
          color={'#1976d2'}
          style={{ cursor: 'pointer' }}
          display={leadRequestSuccessful ? 'inline-block' : 'none'}
          onClick={(e) => setOpenLeadRequestDescModal(true)}
        >
          Learn more
        </Typography>

        <Dialog
          open={openLeadRequestDescModal}
          onClose={(e) => setOpenLeadRequestDescModal(false)}
          fullWidth
          maxWidth='md'
        >
          <DialogContent>
            <DialogContentText>{getHelpModalDescription()}</DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    );

    return text;
  };

  const getHelpModalDescription = () => {
    let text = [,];

    return (
      <Stack spacing={2}>
        <Box>
          <Typography variant='h5'>Salsa-driven lead assignment</Typography>

          <hr style={{ marginTop: '10px' }} />

          <Typography
            variant='body1'
            component='p'
            my={2}
          >
            Salsa considers more than 12 parameters and selects the BEST
            possible lead available for you to dial. Salsa takes into account
            the source of the lead, their background, past interactions if any
            and how other leads that are similar to this lead have performed and
            many more conditions.
          </Typography>
          <Typography
            variant='body1'
            component='p'
            my={2}
          >
            Leads are distributed across more than 64 ranks with the 1st rank
            leads having the highest chances of getting converted. The leads
            that get assigned to you come from a well-defined algorithm which
            takes into account multiple parameters like lead bucket hygiene and
            the quality of interactions logged. However, the primary factor
            determining the rank of the leads is the number of CAP payments in
            the past 7 days.
          </Typography>
          <Typography variant='h6'>FAQs</Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            How many leads can I request?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            You are expected to request 7 leads every day. These are the best
            possible leads that the system assigns based on the floor dynamics,
            the market conditions, your strengths and your bucket hygiene. For a
            four week drive, you can get close to 170 leads. In case you are a
            high consumer and need more leads, you can request 5 additional
            leads. For a four week drive, this makes it close to 290 leads. In
            any case, you cannot request more than 12 leads per day.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            Can I request leads on a non-working day?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Yes, you can request leads on a non-working day, a holiday or even
            late at night. The only thing that you need to ensure is that the
            moment a lead is assigned to you, you need to call them in less than
            15 minutes. You are not expected to add an interaction within 15
            minutes if the call is ongoing.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            How can I get better-ranked leads?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Improve your weekly productivity (number of complete amount payments
            in the past seven days). Dial the newly assigned leads within 15
            minutes, add detailed interactions, follow the lead reachout and
            drop policies well. In short, maintain a good bucket hygiene.
          </Typography>
          <Typography
            variant='h6'
            mt={2}
          >
            My colleague got a better-ranked lead than me. Why?
          </Typography>
          <Typography
            variant='body1'
            component='p'
          >
            Leads are assigned based on several parameters like bucket hygiene,
            productivity of the past 7 days and availability of leads for a
            particular set of ranks in the global pool. Some of these parameters
            are beyond our direct control and depends on the behavior of the end
            user. The best thing to do to ensure that you get the best quality
            leads is to focus on improving your weekly productivity.
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='baseline'
        justifyContent='space-between'
      >
        <Typography
          variant='h4'
          display='inline-block'
          component='div'
          gutterBottom
          marginTop='-2.5rem'
        >
          Bucket search
        </Typography>
        <Box
          display='flex'
          alignItems='flex-end'
          flexDirection='column'
        >
          {isClassSet &&
            window.location.host === constants.SALES2_HOST_NAME &&
            (currentAdminIsIC() || currentAdminIsTrainee()) &&
            (getCurrentAdminId() === 187 ||
              getCurrentAdminId() === 255 ||
              getCurrentAdminId() === 276 ||
              getCurrentAdminId() === 665) &&
            salsaDrivenLeadAssignment()}
        </Box>
      </Box>
      <Divider />
      <br />
      <Search
        hotLeadsCheck={hotLeadsCheck}
        handleHotLeadsSelect={handleHotLeadsSelect}
        searchParams={searchParams}
        filters={globalFilters}
        setFilters={setGlobalFilters}
        handleReset={resetAllFilters}
        fetchData={fetchData}
        fetachingLeadData={fetachingLeadData}
      />
      <LeadsDataTable
        data={leads}
        refreshResults={fetchData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalLeads={totalLeads}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
        queryParams={queryParams}
        fetachingLeadData={fetachingLeadData}
      />
      <Modal
        open={isSpinner}
        onClose={!isSpinner}
        container={() => document.body}
      >
        <Spinner
          text='Requesting a new lead ......'
          modalStyle={{
            height: '75%',
            width: '65%',
          }}
          textStyle={{
            fontSize: '40px',
          }}
          SpinnerStyle={{
            size: 80,
            thickness: 8,
            color: '#6f00ff',
          }}
        />
      </Modal>
      <Modal
        open={isOpenLeadRequestModal}
        onClose={!isOpenLeadRequestModal}
        container={() => document.body}
      >
        <LeadRequestModal
          requestedLeadData={requestedLeadData}
          isOpen={isOpenLeadRequestModal}
          closeHandler={closeLeadRequestModal}
        />
      </Modal>
    </>
  );
}
