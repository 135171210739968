import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import AdminsAutocompleteDropdown from '../admins/AdminAutoComplete';

const Assignment = ({ formValues, formErrors, setFormValues }) => {
  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={2}
      py={2}
      px={2}
      sx={{ background: '#E2EEFE' }}
    >
      <Grid
        item
        xs={12}
        py={2}
      >
        <Typography variant='h6'>Assignment</Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl>
          <AdminsAutocompleteDropdown
            value={formValues.assignee}
            onChangeHandler={(e, newValue) => {
              e.target.name = 'assignee';
              e.target.value = newValue.value;
              setFormValues({
                ...formValues,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControl fullWidth>
          <InputLabel id='product_label'>Product</InputLabel>
          <Select
            type='text'
            name='product'
            label='product'
            labelId='product_label'
            value={formValues.product}
            onChange={handleChange}
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='super30'>Super 30</MenuItem>
            <MenuItem value='personal_coaching'>Personal Coaching</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default Assignment;
