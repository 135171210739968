import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from '@mui/material';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import ManagerActions from './ManagerActions';
import ManagerPoolAction from './ManagerPoolAction';
import Pool from './Pool';
import { useAllAdmins } from '../../components/AdminsContext';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import { sortByFullName } from '../../utils/common';

function ManagerPool() {
  const admin = JSON.parse(localStorage.getItem('admin')) ?? {};
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedManager, setSelectedManager] = useState(admin.id);
  const [freshleads, setFreshleads] = useState([]);
  const [tapLeads, setTapleads] = useState([]);
  const [groupByUtmSource, setGroupByUtmSource] = useState([]);
  const [tag, setTag] = useState();

  const managers = useAllAdmins().allAdmins.filter((e) => {
    const roles = e.roles;
    if (e.access_type === 'allowed' || e.access_type === 'restricted') {
      for (const role of roles) {
        if (role.name === 'lead') {
          return true;
        }
      }
    }
    return false;
  });

  const ics = useAllAdmins().allAdmins.filter((e) => {
    const roles = e.roles;
    if (e.access_type === 'allowed' || e.access_type === 'restricted') {
      for (const role of roles) {
        if (['independent_contributor', 'trainee'].includes(role.name)) {
          return true;
        }
      }
    }
    return false;
  });

  useEffect(() => {
    getTappedLeads();
    getFreshleads();
    getUTMSource();
    getTags();
  }, [selectedManager]);

  const getTappedLeads = async () => {
    let url = `${base_url()}/api/manager_pool/tapped_leads?ids=${selectedManager}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setTapleads(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const getFreshleads = async () => {
    let url = `${base_url()}/api/manager_pool/fresh_leads?ids=${selectedManager}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setFreshleads(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const getUTMSource = async () => {
    let url = base_url() + `/api/manager/manager-pools?id=${selectedManager}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data) && res?.data !== null) {
          const leadsGroupedByField = res?.data.reduce((groups, lead) => {
            const { utm_source, lead_id, Lead_Name } = lead;
            const source = utm_source ? utm_source : 'Unknown Source';
            if (!groups[source]) {
              groups[source] = [];
            }
            groups[source].push({ lead_id, Lead_Name });
            return groups;
          }, {});

          const leadsGroupedArray = Object.keys(leadsGroupedByField).map(
            (utm_source) => ({
              field: utm_source,
              leads: leadsGroupedByField[utm_source],
            })
          );
          setGroupByUtmSource(leadsGroupedArray);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occurred while fetching fresh leads.'
        );
      });
  };

  const getTags = async () => {
    let url = `${base_url()}/api/manager_pool/tag_wise_leads?ids=${selectedManager}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setTag(res?.data);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err?.response?.data?.message ||
            'An error occured while fetching tap leads.'
        );
      });
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom: '3%',
          marginRight: '2%',
        }}
      >
        <Box></Box>
        <FormControl sx={{ minWidth: '250px' }}>
          <InputLabel>Managers</InputLabel>
          <Select
            label='My Dropdown'
            labelId='dropdown-label'
            value={selectedManager}
            onChange={handleManagerChange}
            disabled={
              admin.roles.includes('admin') ||
              admin.roles.includes('senior_manager')
                ? false
                : true
            }
          >
            {managers.length > 0 &&
              sortByFullName(managers, 'fname').map((e) => {
                return (
                  <MenuItem
                    key={e.id}
                    value={e.id}
                  >
                    {e.fname && e.lname
                      ? `${e.fname} ${e.lname}`
                      : 'Name not available'}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>

      <Box>
        {freshleads?.length === 0 && tapLeads?.length === 0 ? (
          <Box
            style={{
              border: '1px solid black',
              paddingLeft: '40%',
              paddingTop: '5%',
              paddingBottom: '5%',
              marginBottom: '2%',
            }}
          >
            <h3 style={{ fontFamily: 'cursive' }}>😔 No leads in pool</h3>
          </Box>
        ) : (
          <>
            <Pool
              freshleads={freshleads}
              tapLeads={tapLeads}
              tag={tag}
              manager={managers.filter((e) => {
                return e.id === selectedManager;
              })}
              groupByUtmSource={groupByUtmSource}
            />
            {admin && managers && selectedManager && ics && (
              <ManagerPoolAction
                selectedManager={selectedManager}
                managers={managers}
                admin={admin}
                ics={ics}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ManagerPool;
