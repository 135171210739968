import React, { useEffect } from 'react';
import {
  TextField,
  Typography,
  Button,
  Box,
  Checkbox,
  RadioGroup,
  Radio,
  Badge,
  FormControlLabel,
} from '@mui/material';
import {
  getTimeZoneFormattedTimestamp,
  currentAdminIsMarketing,
} from '../../utils/common';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const ExternalTabComponents = ({
  flagformorethansixdnp,
  salesClouserPaymentsData,
  setSalesClouserPaymentsData,
  selectedExternalTab,
  setSelectedExternalTab,
  handleCallbackRequest,
  callbackRequestActivity,
  handleCallDialledActivity,
  callDialledActivity,
  newInteractionNote,
  setNewInteractionNote,
  updateSnoozedUntil,
  snoozedUntil,
  handleNotifyMe,
  notifyMe,
  openFileUploads,
  setOpenFileUploads,
  uploadedFiles,
  addInteraction,
  interactionInProgress,
  interactionPlaceholderText,
  // leadDropCheck,
  // handleLeadDropCheck,
}) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const callDialledActivityArray = ['Dialled Once', 'Dialled Twice'];
  const minDateTime = getTimeZoneFormattedTimestamp(new Date());
  const selectedOptionForInteractions = [
    'Detailed Interaction',
    'Did not pick (DNP)',
    'Social Media Interaction',
    'Requested Callback (RCB)',
  ];

  useEffect(() => {
    if (selectedExternalTab === 3) {
      handleCallbackRequest(true);
    } else {
      handleCallbackRequest(false);
    }
  }, []);

  const followUpTimeComponent = () => {
    return (
      <Box>
        <Typography
          variant='h6'
          component='h6'
          sx={{ fontSize: '14px', marginY: 1 }}
        >
          Follow up at
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Today
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {['12 PM', '3 PM', '6 PM', '9 PM'].map((value, index) => (
                  <Button
                    size='small'
                    onClick={() => updateSnoozedUntil('Today', value)}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Tomorrow
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {['9 AM', '12 PM', '6 PM', '9 PM'].map((value, i) => (
                  <Button
                    key={i}
                    size='small'
                    onClick={() => updateSnoozedUntil('Tomorrow', value)}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Custom
              </Typography>
            </Box>
            <Box>
              <TextField
                disabled={currentAdminIsMarketing()}
                inputProps={{
                  min: minDateTime,
                }}
                type='datetime-local'
                name='snoozed_until_1'
                // disabled={selectedExternalTab == 1 && flagformorethansixdnp}
                value={snoozedUntil}
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                }}
                onChange={(e) => updateSnoozedUntil('Custom', e.target.value)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const notiftyMeComponent = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          sx={{ marginY: 1, padding: 0 }}
          {...label}
          onChange={handleNotifyMe}
          checked={notifyMe}
        />
        <Typography sx={{ marginY: 1, marginLeft: '10px' }}>
          Notify me
        </Typography>
      </Box>
    );
  };

  const textFieldComponent = () => {
    return (
      <Box sx={{ backgroundColor: 'white' }}>
        <TextField
          multiline
          rows={3}
          fullWidth
          placeholder={interactionPlaceholderText}
          value={newInteractionNote}
          onChange={(e) => setNewInteractionNote(e.target.value)}
          sx={{
            marginRight: '0.3rem',
          }}
        />
      </Box>
    );
  };

  const saveButtonComponent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Button
          sx={{
            width: '100px',
          }}
          variant='contained'
          onClick={() => addInteraction()}
        >
          Save
        </Button>
      </Box>
    );
  };

  const fileButtonComponent = (selectedExternalTab) => {
    return (
      <Button
        disabled={
          selectedExternalTab === 2
            ? false
            : newInteractionNote.length == 0 || interactionInProgress
        }
        onClick={(e) => setOpenFileUploads(!openFileUploads)}
        variant='contained'
      >
        <Badge
          badgeContent={uploadedFiles.length}
          color='error'
        >
          {selectedExternalTab === 2
            ? 'Social media screenshot'
            : 'Attach file'}
        </Badge>
      </Button>
    );
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        padding: 2,
        backgroundColor: '#f7f7f7',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '-12px',
          marginBottom: '15px',
        }}
      >
        <KeyboardBackspaceIcon
          sx={{ marginY: 1, padding: 0, cursor: 'pointer' }}
          onClick={() => setSelectedExternalTab(4)}
        />
        <Typography
          sx={{
            marginLeft: '10px',
          }}
        >
          {selectedOptionForInteractions[selectedExternalTab]}
        </Typography>
      </Box>

      {selectedExternalTab === 1 && (
        <RadioGroup
          sx={{
            gap: 2,
            display: 'flex',
            flexDirection: 'row',
          }}
          value={callDialledActivity}
          onChange={handleCallDialledActivity}
        >
          {callDialledActivityArray.map((value, index) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '10px',
                boxShadow: 2,
                borderRadius: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  padding: '10px',
                  gap: '10px',
                }}
              >
                <FormControlLabel
                  key={index}
                  value={value}
                  control={<Radio />}
                  label={<Typography>{value}</Typography>}
                />
              </Box>
            </Box>
          ))}
        </RadioGroup>
      )}

      {selectedExternalTab !== 1 && selectedExternalTab !== 2 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {textFieldComponent()}
          <Box>{fileButtonComponent(selectedExternalTab)}</Box>
        </Box>
      )}

      {selectedExternalTab === 2 && (
        <Box>{fileButtonComponent(selectedExternalTab)}</Box>
      )}
      {selectedExternalTab === 2 && uploadedFiles.length > 0 && (
        <>
          {followUpTimeComponent()}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              mt: '10px',
            }}
          >
            {notiftyMeComponent()}
          </Box>
          {textFieldComponent()}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              mt: '10px',
            }}
          >
            {saveButtonComponent()}
          </Box>
        </>
      )}

      {selectedExternalTab !== 2 && (
        <>
          {!(selectedExternalTab === 1 && flagformorethansixdnp) &&
            followUpTimeComponent()}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:
                selectedExternalTab !== 1 ? 'space-between' : 'end',
              mt: '10px',
            }}
          >
            {selectedExternalTab !== 1 && notiftyMeComponent()}
            {saveButtonComponent()}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ExternalTabComponents;
