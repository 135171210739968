import React from 'react';
import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import {
  MyOpenLeads,
  MyExpiredLeads,
  LeadsExpiringIn3Days,
  MyFollowUpsDue,
} from '../pages/MyOpenLeads';
import { RecentlyUpdated } from '../pages/RecentlyUpdated';
import { HotLeads } from '../pages/HotLeads';
import { GlobalSearch } from '../pages/GlobalSearch';
import { DidNotPick } from '../pages/DidNotPick';
import { RequestedCallback } from '../pages/RequestedCallback';
import { LiveCall } from '../pages/LiveCall';
import { MockCalls } from '../pages/MockCalls';
import { MockCallDetails } from '../pages/MockCallDetails';
import { TrainingDashboard } from '../pages/TrainingDashboard';

import Reports from '../pages/Reports/Reports';
import MarketingDashboard from '../pages/MarketingDashboard/MarketingDashboard';
import AdminDashboard from '../pages/Admins/AdminDashboard';
import AdminProfile from './../components/profile/AdminProfile';
import SalsaDashboard from '../pages/Admins/SalsaDashboard';
import ManagerDashboard from '../pages/ManagerDashboard/ManagerDashboard';
import { useAuthStateValue } from '../components/auth/AuthContext';
import Login from '../pages/Login';
import { canAccessRoute } from './role_route_mapper';
import Notifications from '../components/Notifications/Notifications';
import MyConversions from '../components/Conversions/MyConversions';
import NewReciptModal from '../components/Receipt/NewReciptModal';
import PostSalesDashboard from '../components/Conversions/PostSalesDashboard/PostSalesDashboard';
import CurrentLead from '../components/data-table/CurrentLead';
import LeadFunnel from '../components/lead-funnel/LeadFunnel';
import HrDashboard from '../pages/HrDashboard/HrDashboard';
import {
  currentAdminIsIC,
  getCurrentAdminRole,
  getCurrentAdminAllowLeadAssignment,
} from '../utils/common';
import TraineeDashboard from '../components/training/trainee/TraineeDashboard';

// import Interactions from '../pages/hrms/Interactions';
import HRMSRecentInteraction from '../pages/hrms/HRMSRecentInteraction';
import CandidateSearch from '../pages/hrms/CandidateSearch';
import LeadCallDetails from '../components/callAnimation/LeadCallDetails';
import LeadCallDialingProfile from '../components/callAnimation/LeadCallDialingProfile';
import LeadsLayout from '../../src/components/leads/LeadsLayout';
import ProductivityInsights from '../pages/ManagerDashboard/ProductivityInsights/ProductivityInsights';
import Leaderboard from '../components/leaderboard/Leaderboard';
//import TraineeDashboard from '../components/training/trainee/TraineeDashboard';
import IcHomePape from '../pages/IcHomePape.jsx';

import Payment from './../components/payment/Payment.jsx';

import CandidateFollowUpsDue from '../pages/hrms/CandidateFollowUpsDue';
import PaymentStatus from '../components/Payment-Status/PaymentStatus';
import ManagerScreen from '../components/training/trainee/mockcalls/manager/ManagerScreen';
import ICScreen from '../components/training/trainee/mockcalls/IC/ICScreen';
import MockcallTab from '../components/training/trainee/mockcalls/IC/MockcallTab';
import TrainerDashboard from '../components/training/trainer/TrainerDashboard';
import PaymentFinanceDashboard from '../components/users/PostSalesPaymentsFinance/PaymentFinanceDashboard.jsx';
import Profile from '../pages/Profile/Profile.jsx';
import CAPTrackingDashboard from '../pages/ManagerDashboard/CAPTrackingDashboard.jsx';
import ErrorPage from '../pages/Error/ErrorPage.jsx';
import ErrorBoundary from '../components/error/ErrorBoundary.jsx';
import RevenueDashboard from '../components/revenue/RevenueDashboard.jsx';
import FocusedModeLeads from '../pages/FocusedModeLeads.jsx';

function Routing() {
  const [isUserLoggedIn] = useAuthStateValue();
  const { pathname } = useLocation();

  const ValidateRouteAccess = () => {
    /**
     * TODO (Lokesh): Add a fallback end point for each role rather than re-directing all of them to the same "/bucket-search" end point.
     */
    const route = pathname;
    //If a role does not have access to any routes, it will be redirected to the "/bucket-search".
    //However, for the HR role, which lacks access to the bucket search, the redirection will be to the '/dashboard/hr'.
    if (!canAccessRoute(route)) {
      const adminRole = getCurrentAdminRole();
      if (!canAccessRoute(route) && adminRole.includes('hr')) {
        return <Navigate to='/dashboard/hr' />;
      } else if (
        !canAccessRoute(route) &&
        adminRole.includes('talent_acquisition')
      ) {
        return <Navigate to='/dashboard/hr' />;
      } else if (!canAccessRoute(route) && adminRole.includes('interviewer')) {
        return <Navigate to='/hrms/candidate-search' />;
      } else if (
        !canAccessRoute(route) &&
        adminRole.includes('senior_marketing_manager')
      ) {
        return <Navigate to='/dashboard/marketing' />;
      } else if (!canAccessRoute(route) && adminRole.includes('accountant')) {
        return <Navigate to='/dashboard/accountant' />;
      } else if (currentAdminIsIC()) {
        <Navigate to='/ic-home' />;
      } else if (
        adminRole.includes('trainee') &&
        !getCurrentAdminAllowLeadAssignment()
      ) {
        return <Navigate to='/training/modules' />;
      } else {
        return <Navigate to='/bucket-search' />;
      }
    }
    return <Outlet />;
  };

  return (
    <ErrorBoundary>
      <Routes>
        <Route
          exact
          path='/'
          element={
            isUserLoggedIn ? (
              currentAdminIsIC() ? (
                <Navigate
                  to='/ic-home'
                  replace
                />
              ) : (
                <Navigate
                  to='/bucket-search'
                  replace
                />
              )
            ) : (
              <Login />
            )
          }
        />

        <Route
          path='/login'
          element={<Login />}
        />

        <Route
          element={
            isUserLoggedIn ? (
              <Dashboard />
            ) : (
              <Navigate
                to='/login'
                replace
              />
            )
          }
        >
          <Route element={<ValidateRouteAccess />}>
            <Route
              path='/ic-home'
              element={<IcHomePape />}
            />
            <Route path='/leads'>
              <Route
                path='follow-ups-due'
                element={<MyFollowUpsDue />}
              />
              <Route
                path='DNP'
                element={<DidNotPick />}
              />
              <Route
                path='RCB'
                element={<RequestedCallback />}
              />
              {/* <Route path="action-required" element={<ActionRequired />}/> */}
              <Route
                path='hot-leads'
                element={<HotLeads />}
              />
              <Route
                path='total'
                element={<MyOpenLeads />}
              />
              <Route
                path='priority-list'
                element={<FocusedModeLeads />}
              />
              <Route
                path='expired'
                element={<MyExpiredLeads />}
              />
              <Route
                path='expiring'
                element={<LeadsExpiringIn3Days />}
              />
              <Route
                path=':id'
                element={<CurrentLead />}
              />
              <Route
                path='new'
                element={<LeadsLayout />}
              />
            </Route>

            <Route
              path='/recent-interactions'
              element={<RecentlyUpdated />}
            />
            <Route
              path='/reports'
              element={<Reports />}
            />
            <Route
              path='/lead-funnel'
              element={<LeadFunnel />}
            />
            <Route
              path='/bucket-search'
              element={<GlobalSearch />}
            />
            <Route
              path='/payment-status'
              element={<PaymentStatus />}
            />

            <Route
              path='/notifications'
              element={<Notifications />}
            />
            <Route
              path='admin-profile/:username'
              element={<Profile />}
            />

            <Route
              path='/productivity-insights'
              element={<ProductivityInsights />}
            />

            <Route path='/dashboard'>
              <Route
                path='admin'
                element={<AdminDashboard />}
              />
              <Route
                path='manager'
                element={<ManagerDashboard />}
              />

              <Route
                path='marketing'
                element={<MarketingDashboard />}
              />
              <Route
                path='salsa'
                element={<SalsaDashboard />}
              />
              <Route
                path='hr'
                element={<HrDashboard />}
              />
              <Route
                path='accountant'
                element={<Payment />}
              />
              <Route
                path='Revenue'
                element={<RevenueDashboard />}
              />
              <Route
                path='leaderboard'
                element={<Leaderboard />}
              />
            </Route>

            <Route path='/conversions'>
              <Route
                path='my-conversions'
                element={<MyConversions />}
              />
              <Route
                path='generate-receipt'
                element={<NewReciptModal />}
              />
              <Route
                path='post-sales'
                element={<PostSalesDashboard />}
              />
              <Route
                path='cap-tracking'
                element={<CAPTrackingDashboard />}
              />
            </Route>

            <Route path='notifications'>
              <Route
                path=''
                element={<Notifications />}
              />
            </Route>

            <Route path='/hrms'>
              <Route
                path='candidate-search'
                element={<CandidateSearch />}
              />
              <Route
                path='recent-interactions'
                element={<HRMSRecentInteraction />}
              />
              <Route
                path='follow-ups-due'
                element={<CandidateFollowUpsDue />}
              />
            </Route>
            <Route
              path='/admin-profile'
              element={<AdminProfile />}
            />
          </Route>
        </Route>
        <Route
          path='/training'
          element={
            isUserLoggedIn ? (
              <TraineeDashboard />
            ) : (
              <Navigate
                to='/login'
                replace
              />
            )
          }
        >
          <Route element={<ValidateRouteAccess />}>
            {/* right now not required these routes, in next iteration i will remove the route */}
            <Route
              path=''
              element={<Navigate to='modules' />}
            />
            {/* <Route
              path='livecall'
              element={<LiveCall />}
            />
            <Route
              path='mockcalls/:id'
              element={<MockCallDetails />}
            />
            <Route
              path='mockcalls'
              element={<MockCalls />}
            /> */}
            <Route
              path='modules'
              element={<TraineeDashboard />}
            />
            {/* <Route
              path='manager-screen'
              element={<ManagerScreen />}
            />
            <Route
              path='ic-screen'
              element={<ICScreen />}
            />
            <Route
              path='ic-mockcalls'
              element={<MockcallTab />}
            />
            <Route
              path='trainer-dashboard'
              element={<TrainerDashboard />}
            /> */}
          </Route>
        </Route>

        <Route
          path='/leads/:id/call'
          element={<LeadCallDetails />}
        />
        <Route
          path='/leads/:id/call-dialing'
          element={<LeadCallDialingProfile />}
        />
        <Route
          path='*'
          element={<ErrorPage />}
        />
      </Routes>
    </ErrorBoundary>
  );
}

export default Routing;
