import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Divider, Chip } from '@mui/material';

const ManagerPoolSlider = ({ freshLeads2, lead, setLead, setLeadState }) => {
  const [leftLinePosition, setLeftLinePosition] = useState(0);
  const [rightLinePosition, setRightLinePosition] = useState(244);
  const [isDraggingLeft, setIsDraggingLeft] = useState(false);
  const [isDraggingRight, setIsDraggingRight] = useState(false);
  const [isDraggingDiv, setIsDraggingDiv] = useState(false);
  const [startX, setStartX] = useState(0);
  const [initialDivWidth, setInitialDivWidth] = useState(
    rightLinePosition - leftLinePosition
  );
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDraggingLeft && !isDraggingRight && !isDraggingDiv) return;
      if (freshLeads2.length === 0) return;
      const containerWidth = containerRef.current.offsetWidth;
      const offsetX = e.clientX - startX;
      if (isDraggingLeft) {
        const newLeftLinePosition = Math.max(
          0,
          Math.min(leftLinePosition + offsetX, rightLinePosition - 2)
        );
        setLeftLinePosition(newLeftLinePosition);
        setInitialDivWidth(rightLinePosition - newLeftLinePosition);
      }
      if (isDraggingRight) {
        const newRightLinePosition = Math.max(
          leftLinePosition + 2,
          Math.min(rightLinePosition + offsetX, containerWidth)
        );
        setRightLinePosition(newRightLinePosition);
        setInitialDivWidth(newRightLinePosition - leftLinePosition);
      }
      if (isDraggingDiv) {
        const newLeftLinePosition = Math.max(
          0,
          Math.min(leftLinePosition + offsetX, rightLinePosition - 2)
        );
        const newRightLinePosition = Math.max(
          leftLinePosition + 2,
          Math.min(rightLinePosition + offsetX, containerWidth)
        );

        const isAtLeftEdge = newLeftLinePosition === 0;
        const isAtRightEdge = newRightLinePosition === containerWidth;

        if (!isAtLeftEdge && !isAtRightEdge) {
          setLeftLinePosition(newLeftLinePosition);
          setRightLinePosition(newRightLinePosition);
        } else {
          setIsDraggingDiv(false);
        }
      }

      setStartX(e.clientX);
    };

    const handleMouseUp = () => {
      setIsDraggingLeft(false);
      setIsDraggingRight(false);
      setIsDraggingDiv(false);
    };

    if (isDraggingLeft || isDraggingRight || isDraggingDiv) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [
    isDraggingLeft,
    isDraggingRight,
    isDraggingDiv,
    leftLinePosition,
    rightLinePosition,
    startX,
    freshLeads2,
  ]);

  const handleLeftLineMouseDown = (e) => {
    setIsDraggingLeft(true);
    setStartX(e.clientX);
  };

  const handleRightLineMouseDown = (e) => {
    setIsDraggingRight(true);
    setStartX(e.clientX);
  };

  const handleDivMouseDown = (e) => {
    setIsDraggingDiv(true);
    setStartX(e.clientX);
  };

  const containerWidth = containerRef.current
    ? containerRef.current.offsetWidth
    : 0;
  const maxLead = freshLeads2.length;
  const newLead = Math.round((initialDivWidth / containerWidth) * maxLead);

  useEffect(() => {
    if (newLead !== Infinity) {
      setLead(newLead);
      setLeadState(newLead);
    } else {
      setLead(0);
      setLeadState(0);
    }
  }, [newLead, setLead]);

  useEffect(() => {
    if (containerRef.current) {
      const newInitialDivWidth =
        (lead / maxLead) * containerRef.current.offsetWidth;
      if (isNaN(newInitialDivWidth)) {
        setInitialDivWidth(0);
        setRightLinePosition(0);
      } else {
        setInitialDivWidth(newInitialDivWidth);
        setRightLinePosition(leftLinePosition + newInitialDivWidth);
      }
    }
  }, [lead, leftLinePosition, maxLead]);

  return (
    <div
      ref={containerRef}
      style={{
        height: '200px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '60px',
          width: '100%',
          backgroundImage:
            'linear-gradient(to right, #CDDDF0 1px, transparent 2px)',
          backgroundSize: '3px 100%',
          position: 'relative',
        }}
      >
        <div
          onMouseDown={handleLeftLineMouseDown}
          className='leftLine'
          style={{
            height: '85px',
            width: '2px',
            backgroundColor: 'black',
            position: 'absolute',
            left: `${leftLinePosition}px`,
            zIndex: '2',
            bottom: '0',
            cursor: 'col-resize',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-5px',
              left: '-5px',
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              backgroundColor: 'black',
              cursor: 'grab',
            }}
          ></div>
        </div>
        <div
          onMouseDown={handleDivMouseDown}
          className='draggableDiv'
          style={{
            height: '100%',
            width: `${initialDivWidth}px`,
            backgroundColor: newLead !== 0 ? '#383cb2' : 'grey',
            position: 'absolute',
            left: `${leftLinePosition}px`,
            cursor: 'move',
            overflow: 'hidden',
          }}
        >
          <Divider
            sx={{
              marginTop: '18px',
              '&::before, &::after': {
                borderColor: '#fff',
              },
            }}
          >
            <Chip
              sx={{
                color: '#000000',
                backgroundColor: '#fff',
              }}
              label={` ${newLead} leads `}
              size='small'
            />
          </Divider>
        </div>
        <div
          onMouseDown={handleRightLineMouseDown}
          className='rightLine'
          style={{
            height: '85px',
            width: '2px',
            backgroundColor: 'black',
            position: 'absolute',
            left: `${rightLinePosition}px`,
            bottom: '0',
            cursor: 'col-resize',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-5px',
              left: '-5px',
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              backgroundColor: 'black',
              cursor: 'grab',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ManagerPoolSlider;
