import React, { useState, useEffect, useRef } from 'react';
import { useCurrentLead } from '../../CurrentLeadContext';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Tooltip,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import { LeadDetailsModal } from '../LeadDetailsModal';
import { LeadDetailsModalV1 } from '../LeadDetailsModalV1';
import { base_url } from '../../Mode';
import axios from 'axios';
import {
  getCurrentAdminId,
  shouldEnforceFocusMode,
} from '../../../utils/common';
import { CurrentLeadModalContent } from '../../data-table/CurrentLeadModal';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import FocusModeSearchBar from './FocusModeSearchBar';
import constants from '../../../utils/constants';
import CustomBackdrop from '../../../pages/CustomBackdrop';
import CurrentPostSalesLeadComponent from '../../CurrentPostSalesLeadContext';

function FocusModeScreen() {
  const adminId = getCurrentAdminId();
  const {
    leadFocusMode,
    setLeadFocusMode,
    leadFocusModeByManual,
    currentLead,
    setCurrentLead,
    getCurrentLeadData,
    currentLeadInteractions,
    resetCurrentLeadContext,
  } = useCurrentLead();
  const [leadList, setLeadList] = useState([]);
  const currentLeadIndex = useRef(0);
  const currentOffset = useRef(0);
  const followUpText = useRef('');
  const [isNextButtonClickable, setIsNextButtonClickable] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (leadFocusMode) {
      fetchFocusedLeads();
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (currentLead?.snoozed_until_1 || currentLead?.activities) {
      handleNextButtonClickability();
    }
  }, [currentLead, currentLeadInteractions, currentLead?.snoozed_until_1]);

  useEffect(() => {
    if (leadList.length > 0) {
      fetchLeadDetails(leadList[currentLeadIndex.current]);
    }
    return () => {};
  }, [leadList]);

  const handleFocusModeClose = () => {
    setLeadList([]);
    setLeadFocusMode(false);
    setIsNextButtonClickable(false);
    resetCurrentLeadContext();
    currentLeadIndex.current = 0;
    const admin = JSON.parse(localStorage.getItem('admin')) ?? {};
    admin.enforce_focus_mode = false;
    localStorage.setItem('admin', JSON.stringify(admin));
    window.location.reload();
  };
  const handleNextButtonClickability = () => {
    const { snoozed_until_1, status } = currentLead;
    const currentTime = new Date();

    if (
      status === constants.DROPPED ||
      status === constants.COMPLETE_AMOUNT_PAID
    ) {
      setIsNextButtonClickable(true);
      return;
    }

    if (!snoozed_until_1 || new Date(snoozed_until_1) < currentTime) {
      setIsNextButtonClickable(false);
      return;
    }

    if (new Date(snoozed_until_1) > currentTime) {
      setIsNextButtonClickable(true);
      return;
    }

    let lastInteractionTime = null;
    for (let i = currentLeadInteractions.length - 1; i >= 0; --i) {
      if (currentLeadInteractions[i].updated_by === getCurrentAdminId()) {
        lastInteractionTime = new Date(currentLeadInteractions[i].updated_at);
        break;
      }
    }

    if (!lastInteractionTime) {
      setIsNextButtonClickable(false);
      return;
    }

    const MIN_30 = 30 * 60 * 1000;
    const diff = currentTime - lastInteractionTime;
    setIsNextButtonClickable(diff <= MIN_30);
  };

  const fetchLeadDetails = (leadId) => {
    setLoading(true);
    getCurrentLeadData(leadId);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleLeadList = (data) => {
    if (data.length === 0) {
      setLeadList([]);
      setCurrentLead(null);
      currentLeadIndex.current = 0;
      setIsNextButtonClickable(false);
      if (!leadFocusModeByManual) {
        handleFocusModeClose();
      }
      return;
    }
    setLeadList(data);
  };

  const fetchFocusedLeads = () => {
    const date = new Date().toISOString().split('T').join(' ').slice(0, 16);
    const queryParams = [
      `assignee=${adminId}`,
      `closed_at=null`,
      `limit=50`,
      `snoozed_till=${date}`,
      `include_unsnoozed=1`,
      `offset=${currentOffset.current}`,
      `sort_by=is_hot`,
      `lead_focus_mode=${leadFocusModeByManual}`,
    ];
    const url = `${base_url()}/api/focus-mode-leads?` + queryParams.join('&');
    axios
      .get(url)
      .then((res) => {
        handleLeadList(res.data?.leads);
        const { total, follow_up_due_in_3h: dueIn3h } = res.data;
        followUpText.current = `${total} follow ups due right now, and ${dueIn3h.count} in the next 3 hours.`;
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the leads."
        );
      });
  };

  const getNewLead = () => {
    const leadIndex = currentLeadIndex.current + 1;
    if (leadIndex < leadList.length) {
      currentLeadIndex.current = leadIndex;
      fetchLeadDetails(leadList[leadIndex]);
    } else {
      fetchFocusedLeads();
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      <CustomBackdrop open={loading} />

      <DialogTitle>
        <Grid
          container
          display='flex'
          alignContent='center'
          justifyContent='space-between'
        >
          <Grid
            item
            display='flex'
            gap='10px'
            alignItems='center'
            xs={4}
          >
            <CircleIcon sx={{ color: '#12b886' }} />
            <Typography
              sx={{
                fontSize: 'clamp(18px,1.6vh,22px)',
                fontFamily: 'monospace,sans-sirf',
              }}
            >
              Focus Mode! You are wired in, avoid all distractions!
            </Typography>
          </Grid>

          <Grid
            item
            xs={8}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            gap='2rem'
          >
            <FocusModeSearchBar
              {...{
                setLeadList,
                currentLeadIndex: currentLeadIndex.current,
              }}
            />
            {!shouldEnforceFocusMode() && (
              <Tooltip title='close'>
                <IconButton onClick={handleFocusModeClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          {currentLead && (
            <>
              <CurrentLeadModalContent />
              {/* <CurrentPostSalesLeadComponent> */}
              <LeadDetailsModal />
              {/* </CurrentPostSalesLeadComponent> */}
            </>
          )}
          {leadList.length === 0 && leadFocusMode && (
            <Typography
              variant='h4'
              sx={{
                margin: 'auto',
                fontFamily: 'monospace, sans-sirf',
              }}
            >
              No more leads found.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={2}
          direction='row'
          width='50%'
          justifyContent='flex-end'
          alignItems='center'
        >
          <>{followUpText.current}</>
          <Button
            onClick={getNewLead}
            variant='contained'
            disabled={!isNextButtonClickable}
            sx={{
              backgroundColor: '#12b886',
              marginRight: '1vw',
              width: '20%',
            }}
          >
            Next lead
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

export default FocusModeScreen;
