import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { base_url } from '../components/Mode';
import { Typography } from '@mui/material';
import LeadsDataTable from '../components/data-table/LeadsDataTable';

const FocusedModeLeads = () => {
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [columnFilters, setColumnFilters] = useState('');
  useEffect(() => {
    fetchFocusedLeads();

    return () => {};
  }, []);

  const fetchFocusedLeads = async () => {
    try {
      const response = await axios.get(
        base_url() + '/api/focus-mode/lead-list'
      );
      setLeads(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Typography
        variant='h5'
        component='div'
        gutterBottom
        marginTop='-3%'
      >
        Priority List
      </Typography>
      <LeadsDataTable
        data={leads}
        isCalledFromRcb={true}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={limit}
        setPerPage={(entriesPerPage) => setLimit(entriesPerPage)}
        setColumnFilters={(filter) => setColumnFilters(filter)}
      />
    </>
  );
};

export default FocusedModeLeads;
