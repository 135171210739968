import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Badge, Tab, Tabs } from '@mui/material';
import LowCallDurationManagerAction from './LowCallDurationManagerAction';
import LeadDropRequestManagerAction from './LeadDropRequestManagerAction';
import LeadDropRequestManagerActionV1 from './LeadDropRequestManagerActionV1';
import {
  currentAdminIsAdmin,
  currentAdminIsSeniorManager,
  getCurrentAdminId,
} from '../../utils/common';
import { base_url } from '../../components/Mode';
import axios from 'axios';

const ManagerActions = () => {
  const [managerActionTab, setManagerActionTab] = useState(0);
  const [lowCPECount, setLowCPECount] = useState(0);
  const [leadDropRequestCount, setLeadDropRequestCount] = useState(0);
  const handleManagerActionTabChange = (e, newValue) => {
    setManagerActionTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const getLowCPECount = () => {
    const url = `${base_url()}/api/manager_actions?action=pending`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length) {
          setLowCPECount(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLeadDropRequestCount = () => {
    const url = `${base_url()}/api/manager_actions/drop_request?action=pending`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.length) {
          setLeadDropRequestCount(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLowCPECount();
    getLeadDropRequestCount();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={managerActionTab}
        onChange={handleManagerActionTabChange}
        sx={{ marginBottom: 0 }}
      >
        <Tab
          label={
            <div
              style={{
                width: '120px',
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <Badge
                badgeContent={leadDropRequestCount}
                color='error'
                max={9}
                style={{ position: 'absolute', top: 7, right: 2 }}
              />
              Lead Drop
            </div>
          }
        />
        <Tab
          label={
            <div
              style={{
                width: '180px',
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <Badge
                badgeContent={lowCPECount}
                color='error'
                max={9}
                style={{ position: 'absolute', top: 7, right: 2 }}
              />
              Low Call Duration
            </div>
          }
        />
      </Tabs>

      <TabPanel
        value={managerActionTab}
        index={0}
      >
        {/* {currentAdminIsAdmin() ||
        (currentAdminIsSeniorManager() && getCurrentAdminId() === 101) ? (
          <LeadDropRequestManagerActionV1 />
        ) : ( */}
        <LeadDropRequestManagerAction />
        {/* )} */}
      </TabPanel>
      <TabPanel
        value={managerActionTab}
        index={1}
      >
        <LowCallDurationManagerAction />
      </TabPanel>
    </Box>
  );
};

export default ManagerActions;
