import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { base_url } from '../Mode';
import {
  Grid,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useCurrentLead } from '../CurrentLeadContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import {
  getCurrentAdminName,
  splitByUnderscoresAndCapitalize,
} from '../../utils/common';

function LeadCommunications() {
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [whatsappValue, setWhatsappValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    currentLead,
    getCurrentLeadAndUpdateContext,
    getCurrentLeadActivitiesAndUpdateContext,
  } = useCurrentLead();
  const templateId = useRef(-1);
  const templateType = useRef('');
  const dynamicFields = useRef({
    // If keys are updated here, then they must also be updated in the `message_templates` table
    fname: currentLead.fname,
    lname: currentLead.lname,
    whatsapp: currentLead.whatsapp,
    lead_id: currentLead.lead_id,
    admin_fname: getCurrentAdminName(),
  });

  useEffect(() => {
    fetchMessageTemplates();
    return () => {};
  }, []);

  const fetchMessageTemplates = () => {
    const url = base_url() + `/api/message-templates`;
    axios
      .get(url)
      .then((res) => {
        setWhatsappTemplates(res.data.whatsapp);
        setEmailTemplates(res.data.email);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the template names."
        );
      });
  };

  const getPopulatedTemplateMessage = (templateText, defaultFields) => {
    const parsedFields = JSON.parse(defaultFields);
    const text = [];
    const len = templateText.length;

    for (let j = 0; j < len; j += 1) {
      if (templateText[j] !== ':') {
        text.push(templateText[j]);
        continue;
      }

      let key = '';
      while (j + 1 < len && templateText[j + 1] !== ' ') {
        const chr = templateText[j + 1];
        if (
          chr !== '_' &&
          !(chr.toLowerCase() >= 'a' && chr.toLowerCase() <= 'z')
        ) {
          break;
        }
        key += templateText[j + 1];
        j += 1;
      }

      if (key in dynamicFields.current) {
        text.push(dynamicFields.current[key]);
      } else if (key in parsedFields) {
        text.push(parsedFields[key]);
        dynamicFields.current[key] = parsedFields[key];
      } else {
        text.push(':' + key);
      }
    }
    return text.join('');
  };

  const handleDropdownValueChange = (e) => {
    const { name, value } = e.target;
    let id;
    if (name === 'whatsapp_template') {
      id = whatsappTemplates[value];
      setWhatsappValue(value);
    } else {
      id = emailTemplates[value];
      setEmailValue(value);
    }
    templateId.current = id ?? -1;
  };

  const handleWhatsappMessage = () => {
    const phoneNumber = currentLead?.whatsapp ?? currentLead.mobile;
    const url = `${base_url()}/api/message-templates/${templateId.current}`;

    axios
      .get(url)
      .then((res) => {
        const { message, default_values } = res.data;
        const leadMessageToSend = getPopulatedTemplateMessage(
          message,
          default_values
        );

        const whatsappUrl =
          'https://wa.me/' +
          phoneNumber +
          '?text=' +
          encodeURIComponent(leadMessageToSend);
        window.open(whatsappUrl, '_blank');
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || "Couldn't fetch the template message."
        );
      });
  };

  const sendMessageTemplate = () => {
    const postBody = {
      message_template_id: templateId.current,
      dynamic_fields: dynamicFields.current,
      lead_id: currentLead.lead_id,
    };

    const url = base_url() + `/api/message-templates/send`;
    axios
      .post(url, postBody)
      .then(() => {
        setOpen(false);
        getCurrentLeadAndUpdateContext(currentLead.id);
        getCurrentLeadActivitiesAndUpdateContext(currentLead.id);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message ||
            "Couldn't create whatsapp message sent activity"
        );
      });
  };

  const handleButtonClick = (type) => {
    templateType.current = type;
    if (type === 'email') {
      setOpen(true);
    } else {
      handleWhatsappMessage();
    }
  };

  return (
    <Stack
      spacing={2}
      direction='column'
      p={0}
      sx={{
        mt: 3,
        width: '90%',
        marginBottom: '10px',
        border: '1px solid #e0e0e0',
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      <Box
        p={2}
        pb={1}
        sx={{
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant='h6'>Communications</Typography>
          </Grid>

          <TemplateDropdown
            handleDropdownValueChange={handleDropdownValueChange}
            type='whatsapp'
            templatesList={whatsappTemplates}
            value={whatsappValue}
          >
            <Button
              onClick={(e) => handleButtonClick('whatsapp')}
              disabled={
                !(
                  (currentLead?.whatsapp ?? currentLead.mobile) &&
                  whatsappValue
                )
              }
              sx={{
                backgroundColor: '#07b480',
                color: '#fff',
                margin: '10px 0 10px 20px',
                padding: '8px 20px',
                '&:hover': {
                  backgroundColor: '#07b470',
                },
              }}
              startIcon={<WhatsAppIcon />}
            >
              SEND WHATSAPP
            </Button>
          </TemplateDropdown>

          {/* Hiding the email templates for a while. */}

          <TemplateDropdown
            handleDropdownValueChange={handleDropdownValueChange}
            type='email'
            templatesList={emailTemplates}
            value={emailValue}
          >
            <Button
              onClick={(e) => handleButtonClick('email')}
              disabled={!(currentLead?.email && emailValue)}
              sx={{
                backgroundColor: '#cdd3d9',
                color: '#000',
                margin: '10px 0 10px 20px',
                padding: '8px 20px',
                '&:hover': {
                  backgroundColor: '#cdd3d0',
                },
              }}
              startIcon={<EmailIcon />}
            >
              SEND EMAIL
            </Button>
          </TemplateDropdown>
        </Grid>
      </Box>

      <MessageConfirmationDialog
        open={open}
        handleClose={setOpen}
        lead={currentLead}
        templateType={templateType.current}
        handleConfirmation={sendMessageTemplate}
      />
    </Stack>
  );
}

const TemplateDropdown = (props) => {
  const { type, templatesList, children, value, handleDropdownValueChange } =
    props;

  return (
    <Grid
      item
      container
      xs={12}
      display='flex'
      alignItems='center'
    >
      <Grid
        item
        xs={6}
      >
        <Select
          fullWidth
          name={`${type}_template`}
          onChange={(e) => handleDropdownValueChange(e)}
          value={value}
        >
          <MenuItem value=''>None</MenuItem>
          {Object.keys(templatesList).map((templateName) => {
            return (
              <MenuItem value={templateName}>
                {splitByUnderscoresAndCapitalize(templateName)}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>

      <Grid
        item
        xs={6}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const MessageConfirmationDialog = ({
  open,
  handleClose,
  lead,
  templateType,
  handleConfirmation,
}) => {
  const { fname, lname } = lead;
  const leadName = [fname, lname ?? ''].join(' ');

  const handleDialogClose = () => {
    handleClose(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        {templateType === 'whatsapp' ? (
          <>Were you able to send the message to {leadName}?</>
        ) : (
          <>This email will be sent to {leadName}. Do you want to continue?</>
        )}
      </DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => handleConfirmation()}
          autoFocus
        >
          Yes
        </Button>
        <Button
          variant='outlined'
          onClick={handleDialogClose}
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LeadCommunications);
