import { useEffect, useRef, useState } from 'react';
import { Stack, Typography, Paper, Button } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SpeechRecognition, {
  useSpeechRecognition,
} from '../../SpeechRecognition';
import ExplainRecordedAudio from './ExplainRecordedAudio';
import { ConfirmationModal } from './ConfirmationModal';
import { base_url } from '../../Mode';
import axios from 'axios';
import Box from '@mui/material/Box';

const ExplainRecorder = ({
  selectedModule,
  setIsSuccess,
  setHasError,
  setSuccessMessage,
  setErrorMessage,
  setIsRecordingStart,
  isRecordingStart,
  videoTranscript,
  setRefreshPage,
  trnModuleAttemptId,
}) => {
  const {
    transcript,
    resetTranscript,
    startListening,
    stopListening,
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition({
    transcribing: true,
    clearTranscriptOnListen: true,
  });

  const [recordingPaused, setRecordingPaused] = useState(false);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [audioTranscript, setAudioTranscript] = useState(transcript);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showNextButton, setShowNextButton] = useState(trnModuleAttemptId);

  useEffect(() => {
    setAudioTranscript(transcript);
  }, [transcript]);

  useEffect(() => {
    setAudioBlob(null);
  }, [selectedModule]);

  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-GB',
    });
  };

  const stopRecording = () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === 'recording'
      ) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        setRecording(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(`Error stopping recording: ${error.message}`);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, {
            type: 'audio/wav',
          });
          setAudioBlob(audioBlob);
        };

        mediaRecorderRef.current.start();
        setRecording(true);
      })
      .catch((error) => {
        setHasError(true);
        setErrorMessage(`Error accessing microphone: ${error.message}`);
      });
  };

  const resetAudioRecording = () => {
    setAudioBlob(null);
    audioChunks.current = [];
  };

  const handleStartRecording = () => {
    resetAudioRecording();
    resetTranscript();
    setRecordingPaused(false);
    setIsRecordingStopped(false);
    listenContinuously();
    startRecording();
    setIsRecordingStart(true);
  };

  const handleStopRecording = () => {
    setIsRecordingStopped(true);
    SpeechRecognition.stopListening();
    stopRecording();
    setIsRecordingStart(false);
  };

  const handleRecordAgain = () => {
    resetTranscript();
    setRecordingPaused(false);
    setIsRecordingStopped(false);
    setAudioBlob(null);
  };

  const handleSubmit = async () => {
    try {
      // if (!audioTranscript || audioTranscript.trim() === '') {
      //   setHasError(true);
      //   setErrorMessage('Please speak to record your answer.');
      //   return;
      // }

      if (!audioBlob) {
        setHasError(true);
        setErrorMessage('Please speak to record your answer.');
        return;
      }
      setIsSubmit(true);

      let url = base_url() + '/api/module-attempts/evaluate';
      const formData = new FormData();
      //formData.append('answer', audioTranscript);
      formData.append('question', videoTranscript);
      formData.append('audioFile', audioBlob, 'audio.wav');
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const response = await axios.post(url, formData, headers);

      if (!response?.data?.your_speech_score) {
        setHasError(true);
        setErrorMessage('Your speech score is missing or 0.');
        return;
      }

      const apiUrl =
        base_url() + `/api/training-modules/${selectedModule}/module-attempts`;
      const result = await axios.post(apiUrl, {
        score: response?.data?.your_speech_score,
      });
      setIsSubmit(false);
      setIsSuccess(true);
      setSuccessMessage(
        `Answer submitted successfully and your score is ${response?.data?.your_speech_score}%. Passing score is 60%.`
      );
      if (response?.data?.your_speech_score >= 60) {
        setShowNextButton(result?.data?.id);
      }
    } catch (error) {
      console.log('error while file handling', error);
      setHasError(true);
      setErrorMessage('An error occurred during file handling.');
    } finally {
      setAudioBlob(null);
      setIsSubmit(false);
      setAudioTranscript('');
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>No browser support</span>;
  }

  if (!isMicrophoneAvailable) {
    return <span>Please allow access to the microphone</span>;
  }

  const handleNextModule = async () => {
    try {
      const apiUrl =
        base_url() + `/api/training-modules/${showNextButton}/module-attempts`;
      const result = await axios.patch(apiUrl, {});
      setRefreshPage((prev) => prev + 1);
    } catch (error) {
      console.log('error', error);
      setHasError(true);
      setErrorMessage('An error occurred during module-attempts.');
    }
  };

  return (
    <Stack>
      <div style={{ marginLeft: '70%' }}>
        {listening && (
          <Stack
            direction='row'
            spacing={2}
            justifyContent='center'
          >
            <button
              onClick={handleStopRecording}
              color='black'
              style={{
                width: '100%',
                background: 'transparent',
                border: 'none',
                borderRadius: '50%',
              }}
            >
              <StopCircleIcon fontSize='large' />
            </button>
          </Stack>
        )}
        {!isRecordingStopped && listening && !isPaused && (
          <Typography
            variant='p'
            fontSize={'14px'}
            textAlign={'right'}
            sx={{ marginLeft: '40%' }}
            gutterBottom
          >
            Recording...
          </Typography>
        )}
      </div>

      {audioBlob ? (
        <ExplainRecordedAudio
          audioBlob={audioBlob}
          onRecordAgain={handleRecordAgain}
          onSubmit={handleSubmit}
          isSubmit={isSubmit}
        />
      ) : (
        <>
          {!isRecordingStart && (
            <Box mt={3}>
              <Typography
                variant='p'
                fontSize={'14px'}
                textAlign={'center'}
                component='div'
                gutterBottom
              >
                Click the button below and start speaking:
              </Typography>
            </Box>
          )}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Paper
              elevation={3}
              style={{
                borderRadius: '50%',
                marginTop: '40px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                width: '100px',
                height: '100px',
                padding: '10px',
                borderColor: listening ? '#66AFFF' : '#ffffff',
                borderWidth: '2px',
                borderStyle: 'solid',
                transition: 'border 0.5s ease-in-out',
                transform: listening ? 'scale(1.1)' : 'scale(1)',
              }}
            >
              <button
                color='primary'
                style={{
                  width: '100%',
                  background: 'transparent',
                  border: 'none',
                  borderRadius: '50%',
                }}
                onClick={handleStartRecording}
              >
                <KeyboardVoiceIcon />
              </button>
            </Paper>
          </Box>
        </>
      )}

      {!isRecordingStart && !audioBlob && (
        <Button
          disabled={!showNextButton}
          sx={{
            width: '100px',
            border: '1px solid #C4C4C4',
            marginTop: '20%',
            marginLeft: '90%',
          }}
          onClick={() => setOpenConfirmation(true)}
        >
          Next
        </Button>
      )}
      {openConfirmation && (
        <ConfirmationModal
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          handleAccept={handleNextModule}
          massage={'Do you want to go next module?'}
        />
      )}
    </Stack>
  );
};

export default ExplainRecorder;
