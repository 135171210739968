import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { handleDrawerClose } from '../Dashboard';
import { Badge, List, ListItemButton, ListItemText } from '@mui/material';
import axios from 'axios';
import { base_url } from '../../components/Mode';
import NewDropRequestUi from './NewDropRequestUi';
import { useCurrentLead } from '../../components/CurrentLeadContext';
import CustomBackdrop from '../CustomBackdrop';
import ErrorNotifier from '../../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../components/ToastNotifications/SuccessNotifier';
import EastIcon from '@mui/icons-material/East';
import ShieldIcon from '@mui/icons-material/Shield';
import { CopyIcon, getCurrentAdminId } from '../../utils/common';

const LeadDropRequestManagerAction = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectIc, setSelectedIc] = useState('');
  const [leadList, setLeadList] = useState([]);
  const [isNextButtonClickable, setIsNextButtonClickable] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [independentContributor, setIndependentContributor] = useState([]);
  const currentLeadIndex = useRef(0);
  const { currentLead, getCurrentLeadData, updatedDropReasons } =
    useCurrentLead();
  const [leadId, setLeadId] = useState('');
  const [filteredReason, setFilteredReason] = useState(null);
  const [searchLeadId, setSearchLeadId] = useState('');
  const [subordinateAdmins, setSubordinateAdmins] = useState([]);
  const [managerId, setManagerId] = useState('#');
  const [selectedIcForReassignLead, setSelectedIcForReassignLead] =
    useState('');

  useEffect(() => {
    getDropRequest();
    getIndependentContributorDropdown();
    handleDrawerClose();
    fetchLeadDetails(searchLeadId);
  }, [selectIc, searchLeadId, managerId]);

  const getIndependentContributorDropdown = async () => {
    try {
      let url = `${base_url()}/api/all-reportees`;

      if (managerId !== '#') {
        url += `?managerId=${managerId}`;
      }

      const res = await axios.get(url);
      const reportee = res.data;
      const independentContributorsFiltered = reportee.filter(
        (ic) => ic.role === 'independent_contributor'
      );
      const subordinateAdminsFiltered = reportee.filter(
        (ic) => ic.role === 'lead'
      );
      setIndependentContributor(independentContributorsFiltered);
      if (managerId === '#') {
        setSubordinateAdmins(subordinateAdminsFiltered);
      }
    } catch (error) {
      console.error('Error fetching independent contributors:', error);
    }
  };

  const IndependentContributorDropdown = () => {
    return independentContributor.map((ic, index) => (
      <MenuItem
        key={index}
        value={ic?.Admin_id}
      >
        {ic?.Full_Name}
      </MenuItem>
    ));
  };

  const getDropRequest = async () => {
    setLoading(true);
    try {
      const baseUrl = base_url();
      let url = `${baseUrl}/api/manager_actions/drop_request?action=pending`;

      if (searchLeadId) {
        url += `&leadId=${searchLeadId}`;
      }

      if (selectIc) {
        url += `&icId=${selectIc}`;
      }

      if (managerId) {
        url += `&managerId=${managerId}`;
      }

      const response = await axios.get(url);
      setLeadList(response.data);
      setSuccessMessage('Success!');
    } catch (error) {
      console.error('Error fetching drop requests:', error);
      setHasError(true);
      setErrorMessage(
        error.response?.data?.message || "Couldn't get all drop requests."
      );
    }
    setLoading(false);
  };

  const handleIcChange = (event) => {
    setSelectedIc(event.target.value);
  };

  const handleSearchByLeadId = () => {
    if (leadId) {
      setSearchLeadId(leadId);
    }
  };

  const searchByLeadId = (leadId) => {
    let lead = leadId;

    if (lead.charAt(0) === '#') {
      lead = lead.slice(1);
    }

    if (filteredReason) {
      setFilteredReason(null);
    }

    setLeadId(lead);
  };

  const handleChangeForReassignLead = (event) => {
    if (event.target.value) {
      setSelectedIcForReassignLead(event.target.value);
    } else {
      setSelectedIcForReassignLead(event.target.value);
    }
  };

  const reAssignLeadFun = () => {
    if (selectedIcForReassignLead) {
      handleReAssign(leadDetails, selectedIcForReassignLead);
      setSelectedIcForReassignLead('');
    }
  };

  const rejectLeadDropRequest = async (id) => {
    try {
      const body = { status: 'rejected' };
      const res = await axios.patch(
        `${base_url()}/api/manager_actions/${id}/drop_request`,
        body
      );
      if (res.data) {
        getDropRequest();
        setIsSuccess(true);
        setSuccessMessage('Success!');
      }
    } catch (error) {
      console.error('Error rejecting drop request:', error);
      setHasError(true);
      setErrorMessage('An error occurred while rejecting the drop request.');
    }
  };

  const reassignLeadDropRequest = async (id, data) => {
    try {
      const res = await axios.patch(
        `${base_url()}/api/manager_actions/${id}/drop_reassigned`,
        data
      );
      if (res.data) {
        getDropRequest();
        setIsSuccess(true);
        setSuccessMessage('Success!');
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage('An error occurred while re-assigning the drop request.');
    }
  };

  const approveLeadDropRequest = async (arg) => {
    try {
      const body = {
        primaryReason: updatedDropReasons?.primary,
        secondaryReasons: Object.values(updatedDropReasons?.secondary),
        status: 'dropped',
        managerActionId: arg?.id,
        reportee_id: arg?.reportee_id,
      };
      const { lead_id } = arg;
      const res = await axios.put(`${base_url()}/api/users/${lead_id}`, body);
      if (res.data) {
        setIsSuccess(true);
        setSuccessMessage('Lead dropped successfully');
        getDropRequest();
      }
    } catch (error) {
      console.error('Error approving drop request:', error);
      setHasError(true);
      setErrorMessage("Couldn't drop lead.");
    }
  };

  const fetchLeadDetails = async (lead) => {
    setLoading(true);
    try {
      lead && lead?.lead_id && (await getCurrentLeadData(lead.lead_id));
    } catch (error) {
      console.error('Error fetching lead details:', error);
    }
    setLoading(false);
  };

  const getFilteredLeads = () => {
    if (filteredReason) {
      return leadList.filter(
        (entry) =>
          entry?.drop_reasons?.selectedReasons?.primary === filteredReason
      );
    }
    return leadList;
  };

  const getNewLead = () => {
    const filteredLeads = getFilteredLeads();
    let leadIndex = currentLeadIndex.current + 1;
    if (leadIndex === filteredLeads.length) {
      leadIndex = 0;
    }
    currentLeadIndex.current = leadIndex;
    fetchLeadDetails(filteredLeads[leadIndex]);
  };

  const reasonCounts = {};
  leadList?.forEach((entry) => {
    const primaryReason = entry?.drop_reasons?.selectedReasons?.primary;
    reasonCounts[primaryReason] = (reasonCounts[primaryReason] || 0) + 1;
  });

  const handleReAssign = (dropReq, icId) => {
    const { lead_id } = dropReq;
    const managerId = getCurrentAdminId();
    const body = { assignee: icId };
    const data = { status: 'reassigned', managerId: managerId };
    axios
      .put(`${base_url()}/api/users/${lead_id}`, body)
      .then((res) => {
        if (res.data) {
          setIsSuccess(true);
          setSuccessMessage('Success!');
          reassignLeadDropRequest(lead_id, data);
        }
      })
      .catch((err) => {
        console.log('Error reassigning lead:', err.response.data.message);
        setHasError(true);
        setErrorMessage(
          err.response.data.message ||
            'An error occurred while reassigning this lead'
        );
      });
  };

  const handleClick = (reason) => {
    setFilteredReason(reason === filteredReason ? null : reason);
    currentLeadIndex.current = 0;
  };

  const leadDetails = getFilteredLeads()[currentLeadIndex.current] || {};

  const findFullNameOfSelectedIC = (selectedAdminId) => {
    const selectedIC = independentContributor.find(
      (ic) => ic.Admin_id === selectedAdminId
    );
    return selectedIC ? selectedIC.Full_Name : '';
  };

  let copyText = [];

  // if (leadDetails.lead_name) {
  //   copyText.push(leadDetails.lead_name);
  // }

  copyText.push('#' + leadDetails.lead_id);
  copyText = copyText.join(' ');
  let LeadIdCopyIcon = CopyIcon(copyText);

  // const getmanagerDropdown = async () => {
  //   const res = await axios.get(
  //     `${base_url()}/api/manager_actions/subordinate_managers`
  //   );
  //   setSubordinateAdmins(res.data);
  // };

  const managerDropdown = () => {
    return subordinateAdmins.map((manager, index) => {
      return (
        <MenuItem
          key={index}
          value={manager?.Admin_id}
        >{`${manager?.Full_Name}`}</MenuItem>
      );
    });
  };

  return (
    <Box>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      <CustomBackdrop open={loading} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: 160,
          marginBottom: 2,
          gap: 2,
        }}
      >
        {subordinateAdmins && subordinateAdmins.length > 0 ? (
          <FormControl sx={{ minWidth: 180 }}>
            <InputLabel
              id='managers-label'
              sx={{
                marginBottom: 2,
                paddingRight: 1,
                marginTop: '-8px',
              }}
            >
              Managers
            </InputLabel>
            <Select
              labelId='managers-label'
              id='manager-select'
              value={managerId}
              label='Managers'
              size='small'
              onChange={(e) => {
                setManagerId(e.target.value);
              }}
            >
              <MenuItem value={''}>Select Manager</MenuItem>
              {managerDropdown()}
            </Select>
          </FormControl>
        ) : (
          ''
        )}
        <FormControl sx={{ minWidth: 120 }}>
          <TextField
            size='small'
            label='Search by Lead ID'
            value={leadId ? '#' + leadId : ''}
            onChange={(e) => searchByLeadId(e.target.value)}
          />
        </FormControl>
        <Button
          variant='contained'
          disabled={!leadId || !currentLead}
          onClick={handleSearchByLeadId}
        >
          Search Lead
        </Button>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel
            id='select-ic-label'
            sx={{
              marginTop: '-8px',
              paddingRight: 1,
            }}
          >
            Select IC
          </InputLabel>
          <Select
            labelId='select-ic-label'
            id='action-select'
            value={selectIc}
            label='Select IC'
            size='small'
            onChange={handleIcChange}
          >
            <MenuItem value={''}>Select IC</MenuItem>
            {IndependentContributorDropdown()}
          </Select>
        </FormControl>
      </Box>

      {leadList.length > 0 ? (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <div style={{ border: '1px solid black' }}>
              <Grid container>
                <Grid
                  item
                  xs={2.5}
                >
                  <div style={{ height: '100%' }}>
                    <Box sx={{ padding: '10px' }}>
                      <List>
                        {Object.entries(reasonCounts).map(([reason, count]) => (
                          <ListItemButton
                            key={reason}
                            onClick={() => handleClick(reason)}
                            sx={{
                              cursor: 'pointer',
                              display: 'flex',
                              gap: '3px',
                              borderBottom: '1px solid #D3D3D3',
                            }}
                            selected={reason === filteredReason}
                          >
                            <ListItemText primary={reason || 'Loading...'} />
                            <Badge
                              color='error'
                              badgeContent={count}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Box>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={9.5}
                >
                  <div style={{ height: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                        borderLeft: '1px solid black',
                        borderBottom: '1px solid black',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                        }}
                      >
                        <Button
                          variant='contained'
                          disabled={selectedIcForReassignLead || !currentLead}
                          onClick={() => approveLeadDropRequest(leadDetails)}
                        >
                          Accept Drop
                        </Button>
                        <Button
                          variant='contained'
                          disabled={selectedIcForReassignLead || !currentLead}
                          onClick={() => rejectLeadDropRequest(leadDetails?.id)}
                        >
                          Reject Drop
                        </Button>
                        <FormControl
                          labelId='select-ic-label'
                          size='small'
                          disabled={!currentLead}
                          sx={{
                            minWidth: 120,
                          }}
                        >
                          <InputLabel id='select-ic-label'>
                            Select IC
                          </InputLabel>
                          <Select
                            labelId='select-ic-label'
                            id='action-select'
                            label='Select IC'
                            size='small'
                            value={selectedIcForReassignLead}
                            onChange={handleChangeForReassignLead}
                          >
                            <MenuItem value={''}>None</MenuItem>
                            {IndependentContributorDropdown()}
                          </Select>
                        </FormControl>
                        <Button
                          variant='contained'
                          disabled={!selectedIcForReassignLead}
                          onClick={() => reAssignLeadFun()}
                        >
                          Reassign
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant='outlined'
                          onClick={getNewLead}
                          disabled={isNextButtonClickable || !currentLead}
                          endIcon={<EastIcon />}
                          sx={{
                            width: '150px',
                          }}
                        >
                          Next lead
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        height: '50px',
                        backgroundColor: '#a6d7a8',
                        color: '#fff',
                        borderLeft: '1px solid black',
                        borderBottom: '1px solid black',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '20px',
                        gap: 1,
                      }}
                    >
                      <ShieldIcon />
                      <Typography sx={{ textAlign: 'center' }}>
                        {`${leadDetails?.lead_name ?? 'NA'} #${
                          leadDetails?.lead_id ?? 'NA'
                        }`}
                      </Typography>
                      {LeadIdCopyIcon}
                    </Box>

                    {leadDetails?.lead_id && (
                      <NewDropRequestUi lead_id={leadDetails?.lead_id} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%',
              border: '1px solid black',
              textAlign: 'center',
              padding: '50px 100px',
            }}
          >
            {searchLeadId ? (
              <Typography>No lead found</Typography>
            ) : selectIc ? (
              <Typography>
                🎉 Yay! you've taken actions on{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {`${findFullNameOfSelectedIC(selectIc)}'s`}
                </span>{' '}
                drop requests.
              </Typography>
            ) : (
              <Typography>
                🎉 Yay! you've taken actions on all the drop requests.
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LeadDropRequestManagerAction;
