import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import constants from '../../../utils/training/constants';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import ExplainRecorder from './ExplainRecorder';

const Explain = ({
  selectedModule,
  sendClickDataForTrainee,
  url,
  videoTranscript,
  setRefreshPage,
  trnModuleAttemptId,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isRecordingStart, setIsRecordingStart] = useState(false);

  useEffect(() => {
    sendClickDataForTrainee(constants.EXPLAIN, selectedModule);
    return () => {};
  }, [selectedModule, sendClickDataForTrainee]);

  return (
    <div>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      <ExplainRecorder
        selectedModule={selectedModule}
        setErrorMessage={setErrorMessage}
        setHasError={setHasError}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
        setIsRecordingStart={setIsRecordingStart}
        isRecordingStart={isRecordingStart}
        videoTranscript={videoTranscript}
        setRefreshPage={setRefreshPage}
        trnModuleAttemptId={trnModuleAttemptId}
      />
      {/* {isRecordingStart && (
        <iframe
          src={url}
          title='PDF'
          width='100%'
          height='600px'
          style={{ border: 'none' }}
        />
      )} */}
      {/* {isRecordingStart && !url && (
        <Typography sx={{ marginLeft: '40%', marginTop: '-50%' }}>
          Pdf is not avaliable.
        </Typography>
      )} */}
    </div>
  );
};

export default Explain;
